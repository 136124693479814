import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { ElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType, InputFilterField, InputValueType } from '@modules/fields';
import { controlValue, isSet } from '@shared';

@Component({
  selector: 'app-customize-bar-input-edit',
  templateUrl: './customize-bar-input-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarInputEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() label: string;
  @Input() required = false;
  @Input() additional: string;
  @Input() documentation: string;
  @Input() documentationLabel: string;
  @Input() documentationSource: string;
  @Input() labelClasses: string | string[];

  @Input() itemForm: FormGroup;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  // If need to specify inner path inside contextElement (relative to contextElement)
  @Input() contextElementPath: (string | number)[];
  // If need multiple path inside contextElement (relative to contextElement + contextElementPath)
  @Input() contextElementPaths: (string | number)[][];
  @Input() bindComponents = false;
  @Input() element: ElementItem;
  @Input() staticValueField: FieldType;
  @Input() staticValueParams: Object;
  @Input() staticValueVerticalHeight: number;
  @Input() filterFields: InputFilterField[] = [];
  @Input() userInput = false;
  @Input() focusedInitial = false;
  @Input() placeholder = 'Text';
  @Input() formulaPlaceholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() resetEnabled = true;
  @Input() classes: string[] = [];
  @Input() fill = false;
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();
  inputValueTypes = InputValueType;
  staticValueParamsDisplay: Object;
  overrideFocusedInitial = false;
  itemForm$ = new BehaviorSubject<FormGroup>(undefined);
  staticValueType = false;
  fieldTypes = FieldType;
  booleanValueEquals = (lhs, rhs) => lhs === rhs;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.itemForm$
      .pipe(
        switchMap(form => controlValue(form.controls['value_type'])),
        untilDestroyed(this)
      )
      .subscribe(valueType => {
        this.staticValueType = this.isStaticValueType(valueType);
        this.cd.markForCheck();
      });

    this.itemForm$
      .pipe(
        switchMap(form => form.controls['static_value'].valueChanges.pipe(map(() => form))),
        filter(() => this.staticValueType),
        untilDestroyed(this)
      )
      .subscribe(form => {
        if (!isSet(form.controls['value_type'].value)) {
          form.controls['value_type'].patchValue(InputValueType.StaticValue);
        }
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['staticValueParams'] || changes['fill'] || changes['classes']) {
      this.staticValueParamsDisplay = this.getStaticValueParams();
    }

    if (changes['itemForm']) {
      this.itemForm$.next(this.itemForm);
    }
  }

  getStaticValueParams(): Object {
    return {
      background: true,
      ...this.staticValueParams,
      output_format: undefined,
      classes: [...(this.fill ? ['select_fill', 'input_fill'] : []), ...this.classes],
      thumb_inside: true,
      fixed_height: true,
      map_height: 280
    };
  }

  isStaticValueType(valueType: InputValueType) {
    return !isSet(valueType) || valueType == InputValueType.StaticValue;
  }

  toggleFormula() {
    const currentValueType = this.itemForm.controls['value_type'].value;

    if (this.isStaticValueType(currentValueType)) {
      this.itemForm.controls['value_type'].patchValue(InputValueType.Formula);

      const staticValue = this.itemForm.controls['static_value'].value;
      if (isSet(staticValue)) {
        this.itemForm.controls['formula_value'].patchValue(JSON.stringify(staticValue));
      }

      this.overrideFocusedInitial = true;
    } else {
      this.itemForm.controls['value_type'].patchValue(InputValueType.StaticValue);
    }
  }
}
