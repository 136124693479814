var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DragAxis } from '@common/drag-drop2';
import { CustomizeBarContext } from '@modules/customize-bar';
import { createFormFieldFactory } from '@modules/fields';
import { MenuBlock, MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { capitalize, controlValue, getNumberOrdinal, isSet } from '@shared';
import { CustomizeBarPagesEditComponent } from '../customize-bar-pages-edit/customize-bar-pages-edit.component';
import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
var CustomizeBarMenuEditComponent = /** @class */ (function () {
    function CustomizeBarMenuEditComponent(currentProjectStore, activatedRoute, customizeBarContext, routing, cd) {
        this.currentProjectStore = currentProjectStore;
        this.activatedRoute = activatedRoute;
        this.customizeBarContext = customizeBarContext;
        this.routing = routing;
        this.cd = cd;
        this.blockControlPreviewHover$ = new BehaviorSubject(undefined);
        this.blockControlHover = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.fromAppSettings = false;
        this.layoutGroups = [
            {
                label: 'Vertical',
                options: [
                    {
                        layout: MenuBlockLayout.LeftThin,
                        image: 'layout-primary-menu-left',
                        title: 'Thin width',
                        subtitle: 'Left position',
                        color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftThin)
                    },
                    {
                        layout: MenuBlockLayout.LeftWide,
                        image: 'layout-menu-left',
                        title: 'Wide width',
                        subtitle: 'Left position'
                    }
                ]
            },
            {
                label: 'Horizontal',
                options: [
                    {
                        layout: MenuBlockLayout.TopThin,
                        image: 'layout-primary-menu-top',
                        title: 'Primary menu',
                        subtitle: 'Top position',
                        color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftThin)
                    },
                    {
                        layout: MenuBlockLayout.TopThin,
                        image: 'layout-menu-top',
                        title: 'Secondary menu',
                        subtitle: 'Top position',
                        color: ''
                    },
                    {
                        layout: MenuBlockLayout.TopContentThin,
                        image: 'layout-menu-top-content',
                        title: 'Inner menu',
                        subtitle: 'Content top position'
                    }
                ]
            }
        ];
        this.blockItems = [];
        this.blockControlHover$ = new BehaviorSubject(undefined);
        this.dragAxis = DragAxis;
    }
    CustomizeBarMenuEditComponent.prototype.trackMenuBlockItemFn = function (i, item) {
        return item.control.instance.uid;
    };
    CustomizeBarMenuEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.fromAppSettings = !!this.activatedRoute.snapshot.queryParams['app_settings'];
        controlValue(this.menuForm.controls.blocks)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateBlockItems(); });
        this.blockControlHover$.pipe(untilDestroyed(this)).subscribe(function (value) { return _this.blockControlHover.emit(value); });
    };
    CustomizeBarMenuEditComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarMenuEditComponent.prototype.updateBlockItems = function () {
        var _this = this;
        if (this.blockItemParamSubscription) {
            this.blockItemParamSubscription.unsubscribe();
            this.blockItemParamSubscription = undefined;
        }
        var leftI = 0;
        var topI = 0;
        this.blockItems = this.menuForm.controls.blocks.controls.map(function (control) {
            var title;
            if (!control.controls.layout.value) {
                title = 'New menu';
            }
            else if (MenuBlockLayouts.isLeft(control.controls.layout.value)) {
                title = "Left " + capitalize(getNumberOrdinal(leftI + 1)) + " menu";
                leftI += 1;
            }
            else if (MenuBlockLayouts.isTop(control.controls.layout.value)) {
                title = "Top " + capitalize(getNumberOrdinal(topI + 1)) + " menu";
                topI += 1;
            }
            return {
                uid: control.instance ? control.instance.uid : undefined,
                title: title,
                control: control
            };
        });
        this.cd.markForCheck();
        this.blockItemParamSubscription = this.activatedRoute.params
            .pipe(map(function (params) { return params['uid']; }), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function (uid) {
            if (isSet(uid)) {
                var blockItem = _this.blockItems.find(function (item) { return item.uid == uid; });
                if (blockItem) {
                    _this.editMenuBlockItem(blockItem);
                }
                else {
                    _this.navigateDefault();
                }
            }
            else {
                _this.resetEditMenuBlockItem();
            }
        });
    };
    CustomizeBarMenuEditComponent.prototype.addMenuBlock = function (option) {
        var block = new MenuBlock();
        block.generateUid();
        block.layout = option.layout;
        block.accentColor = option.color;
        var control = this.menuForm.controls.blocks.appendControl(block);
        control.applyDefaultState();
    };
    CustomizeBarMenuEditComponent.prototype.navigateMenuBlockItem = function (item) {
        var link = this.currentProjectStore.instance.settingsMenuLink(item.uid);
        this.routing.navigateApp(link, { queryParams: __assign({}, (this.fromAppSettings && { app_settings: '1' })) });
    };
    CustomizeBarMenuEditComponent.prototype.navigateDefault = function () {
        var link = this.currentProjectStore.instance.settingsMenuLink();
        this.routing.navigateApp(link);
    };
    CustomizeBarMenuEditComponent.prototype.editMenuBlockItem = function (item) {
        var prevItemUid = this.editingBlockItem ? this.editingBlockItem.uid : undefined;
        var itemUid = item.uid;
        if (prevItemUid === itemUid) {
            return;
        }
        this.resetEditMenuBlockItem();
        this.editingBlockItem = item;
        this.customizeBarContext.appendSettingsComponent({
            component: CustomizeBarPagesEditComponent,
            inputs: {
                blockControl: item.control,
                startItemsControl: item.control.controls.start_items,
                centerItemsControl: item.control.controls.center_items,
                endItemsControl: item.control.controls.end_items,
                title: item.title,
                layout: item.control.controls.layout.value
            }
        });
    };
    CustomizeBarMenuEditComponent.prototype.resetEditMenuBlockItem = function () {
        var _this = this;
        var currentComponentsCount = this.customizeBarContext.settingsComponents.length;
        if (currentComponentsCount <= 1) {
            return;
        }
        this.editingBlockItem = undefined;
        var popComponents = currentComponentsCount - 1;
        range(popComponents).forEach(function () { return _this.customizeBarContext.popSettingsComponent(); });
    };
    CustomizeBarMenuEditComponent.prototype.dragDrop = function (event) {
        moveItemInArray(this.menuForm.controls.blocks.controls, event.previousIndex, event.currentIndex);
        this.menuForm.controls.blocks.updateValueAndValidity();
    };
    CustomizeBarMenuEditComponent.prototype.backToAppSettings = function () {
        this.routing.navigateApp(this.currentProjectStore.instance.settingsLayoutLink('appearance'));
    };
    return CustomizeBarMenuEditComponent;
}());
export { CustomizeBarMenuEditComponent };
