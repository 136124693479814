/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/popup2/popup2.component.ngfactory";
import * as i2 from "../../../../ui/components/popup2/popup2.component";
import * as i3 from "../../../../ui/components/page-block/page-block.component.ngfactory";
import * as i4 from "../../../../ui/components/page-block/page-block.component";
import * as i5 from "../../../../ui/directives/page-block-defaults/page-block-defaults.directive";
import * as i6 from "../scanner/scanner.component.ngfactory";
import * as i7 from "../scanner/scanner.component";
import * as i8 from "../../../../common/notifications/services/notification/notification.service";
import * as i9 from "./scanner-popup.component";
import * as i10 from "../../../../common/popups/data/popup-ref";
var styles_ScannerPopupComponent = [];
var RenderType_ScannerPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScannerPopupComponent, data: {} });
export { RenderType_ScannerPopupComponent as RenderType_ScannerPopupComponent };
export function View_ScannerPopupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "app-popup2", [], null, null, null, i1.View_Popup2Component_0, i1.RenderType_Popup2Component)), i0.ɵdid(1, 114688, null, 0, i2.Popup2Component, [], { size: [0, "size"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 12, "div", [["class", "custom-page-popup custom-page-popup_style_background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "custom-page-popup__header"]], [[2, "custom-page-popup__header_overlay", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "custom-page-popup__header-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "custom-page-popup__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Scan code"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "custom-page-popup__header-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "a", [["class", "custom-page-popup__close icon-close"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 5, "div", [["class", "custom-page-popup__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "app-page-block", [], [[2, "page-block", null], [2, "page-block_title", null], [2, "page-block_fill", null], [2, "page-block_first", null], [2, "page-block_last", null], [2, "page-block_size_m", null], [2, "page-block_margin-size_xxs", null], [2, "page-block_margin-size_xs", null], [2, "page-block_margin-size_s", null], [2, "page-block_margin-size_m", null], [2, "page-block_margin-size_l", null], [2, "page-block_padding-size_none", null], [2, "page-block_padding-size_xxxs", null], [2, "page-block_padding-size_xxs", null], [2, "page-block_padding-size_xs", null], [2, "page-block_padding-size_s", null], [2, "page-block_padding-size_m", null]], null, null, i3.View_PageBlockComponent_0, i3.RenderType_PageBlockComponent)), i0.ɵdid(11, 114688, null, 0, i4.PageBlockComponent, [[2, i5.PageBlockDefaultsDirective]], null, null), (_l()(), i0.ɵeld(12, 0, null, 0, 2, "div", [], [[4, "height", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "app-scanner", [], null, [[null, "scan"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scan" === en)) {
        var pd_0 = (_co.onScan($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ScannerComponent_0, i6.RenderType_ScannerComponent)), i0.ɵdid(14, 4964352, null, 0, i7.ScannerComponent, [i0.ElementRef, i8.NotificationService, i0.ChangeDetectorRef], { scanningInitial: [0, "scanningInitial"], scanConfirm: [1, "scanConfirm"] }, { scan: "scan" })], function (_ck, _v) { var currVal_0 = "s"; _ck(_v, 1, 0, currVal_0); _ck(_v, 11, 0); var currVal_20 = true; var currVal_21 = true; _ck(_v, 14, 0, currVal_20, currVal_21); }, function (_ck, _v) { var currVal_1 = false; _ck(_v, 3, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 11).cls; var currVal_3 = i0.ɵnov(_v, 11).title; var currVal_4 = i0.ɵnov(_v, 11).fill; var currVal_5 = i0.ɵnov(_v, 11).first; var currVal_6 = i0.ɵnov(_v, 11).last; var currVal_7 = i0.ɵnov(_v, 11).sizeM; var currVal_8 = i0.ɵnov(_v, 11).marginSizeXXS; var currVal_9 = i0.ɵnov(_v, 11).marginSizeXS; var currVal_10 = i0.ɵnov(_v, 11).marginSizeS; var currVal_11 = i0.ɵnov(_v, 11).marginSizeM; var currVal_12 = i0.ɵnov(_v, 11).marginSizeL; var currVal_13 = i0.ɵnov(_v, 11).paddingSizeNone; var currVal_14 = i0.ɵnov(_v, 11).paddingSizeXXXS; var currVal_15 = i0.ɵnov(_v, 11).paddingSizeXXS; var currVal_16 = i0.ɵnov(_v, 11).paddingSizeXS; var currVal_17 = i0.ɵnov(_v, 11).paddingSizeS; var currVal_18 = i0.ɵnov(_v, 11).paddingSizeM; _ck(_v, 10, 1, [currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18]); var currVal_19 = 400; _ck(_v, 12, 0, currVal_19); }); }
export function View_ScannerPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-scanner-popup", [], null, null, null, View_ScannerPopupComponent_0, RenderType_ScannerPopupComponent)), i0.ɵdid(1, 114688, null, 0, i9.ScannerPopupComponent, [i10.PopupRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScannerPopupComponentNgFactory = i0.ɵccf("app-scanner-popup", i9.ScannerPopupComponent, View_ScannerPopupComponent_Host_0, {}, { scan: "scan", cancelled: "cancelled" }, []);
export { ScannerPopupComponentNgFactory as ScannerPopupComponentNgFactory };
