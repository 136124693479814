var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ActionItem, ViewSettingsAction } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { Input as FieldInput } from '@modules/fields';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { ActionsEditForm } from './actions-edit.form';
var ActionsEditComponent = /** @class */ (function () {
    function ActionsEditComponent(form, customizeBarService, customizeBarContext, cd) {
        this.form = form;
        this.customizeBarService = customizeBarService;
        this.customizeBarContext = customizeBarContext;
        this.cd = cd;
        this.options = {};
        this.pin = false;
        this.contextPass = true;
        this.firstInit = false;
    }
    ActionsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init(this.control);
        this.form.form.statusChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
    };
    ActionsEditComponent.prototype.ngOnDestroy = function () { };
    ActionsEditComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.form.controls, event.previousIndex, event.currentIndex);
            this.form.form.updateValueAndValidity();
        }
    };
    ActionsEditComponent.prototype.toggleItemPinned = function (control) {
        var item = cloneDeep(control.value);
        item['pinned'] = !item['pinned'];
        control.patchValue(item);
        this.cd.markForCheck();
    };
    ActionsEditComponent.prototype.addItem = function () {
        var actionItemClass = this.options.actionItemClass || ActionItem;
        var item = new actionItemClass();
        item.verboseNameInput = new FieldInput().deserializeFromStatic('value', 'New Action');
        var control = this.form.createItem(item);
        this.form.arrayAppend(control);
        this.editItem(control);
    };
    ActionsEditComponent.prototype.removeItem = function (control) {
        this.form.arrayRemove(control);
        this.cd.markForCheck();
    };
    ActionsEditComponent.prototype.editItem = function (control, remove) {
        var _this = this;
        if (remove === void 0) { remove = false; }
        this.customizeBarService
            .customizeActionItem({
            options: __assign({}, this.options, { actionItem: control.value, actionLabel: this.actionLabel, visibleInput: control.value.visibleInput }),
            context: this.customizeBarContext,
            viewContext: this.context,
            viewContextElement: this.contextElement,
            viewContextElementPath: this.contextElementPath,
            viewContextElementPaths: this.contextElementPaths,
            viewContextPass: this.contextPass,
            deleteEnabled: remove,
            object: this.object,
            append: true,
            firstInit: this.firstInit
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
                var instance = e.args['result'];
                var visibleInput = e.args['visible_input'];
                if (_this.options.visibleEditable && instance instanceof ViewSettingsAction) {
                    instance.visibleInput = visibleInput;
                }
                control.patchValue(instance);
            }
            else if (e.type == CustomizeBarEditEventType.Deleted) {
                _this.removeItem(control);
            }
        });
    };
    return ActionsEditComponent;
}());
export { ActionsEditComponent };
