import { AgmMap, LatLngBounds, LatLngBoundsLiteral, MapTypeStyle } from '@agm/core';
import { AgmMarkerCluster } from '@agm/js-marker-clusterer';
import { ClusterStyle } from '@agm/js-marker-clusterer/services/google-clusterer-types';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as Color from 'color';
import clamp from 'lodash/clamp';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { parse } from 'wellknown';

import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { AppConfigService } from '@core';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { colors, parseColor } from '@modules/colors';
import { CustomViewsStore } from '@modules/custom-views';
import {
  CustomizeService,
  getModelAttributesByColumns,
  MapLocationStorage,
  MapSettings,
  rawListViewSettingsColumnsToViewContextOutputs,
  ViewSettingsService,
  ViewSettingsStore
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import { coveredByFieldLookup, gteFieldLookup, lteFieldLookup } from '@modules/field-lookups';
import {
  applyParamInput$,
  applyParamInputs$,
  DisplayField,
  DisplayFieldType,
  FieldType,
  Input as FieldInput,
  LOADING_VALUE,
  NOT_SET_VALUE,
  ParameterField
} from '@modules/fields';
import { EMPTY_FILTER_VALUES, FilterItem2, Sort } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import {
  BOTTOM_LEFT_LOCATION_OUTPUT,
  BOTTOM_RIGHT_LOCATION_OUTPUT,
  CENTER_LOCATION_OUTPUT,
  ColumnsModelListStore,
  EMPTY_OUTPUT,
  HAS_SELECTED_ITEM_OUTPUT,
  ITEM_OUTPUT,
  ListItem,
  NO_SELECTED_ITEM_OUTPUT,
  SELECTED_ITEM_OUTPUT,
  TOP_LEFT_LOCATION_OUTPUT,
  TOP_RIGHT_LOCATION_OUTPUT,
  ZOOM_OUTPUT
} from '@modules/list';
import { ListLayoutComponent, ListState, serializeDataSourceColumns } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore, ModelService, ModelUtilsService } from '@modules/model-queries';
import { Model, ORDER_BY_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { GetQueryOptions, paramsToGetQueryOptions } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { ThemeService } from '@modules/theme';
import { firstSet, isColorHex, isSet, MapDarkStyles, MapStyles, numberToHex, parseNumber } from '@shared';

import { containsBounds, coordinatesBounds, coordinatesCenter, getBoundsZoomLevel } from '../../utils/map';

export interface MapMarker {
  latitude: number;
  longitude: number;
  title: Observable<string>;
  item: ListItem;
}

export interface MapState extends ListState<MapSettings> {
  locationStorage?: MapLocationStorage;
  locationField?: string;
  locationLatitudeField?: string;
  locationLongitudeField?: string;
  locationInput?: FieldInput;
  zoomInput?: FieldInput;
  bounds?: LatLngBoundsLiteral;
  theme?: string;
}

function getListStateFetch(state: MapState): Object {
  return {
    dataSource: state.dataSource
      ? {
          ...state.dataSource.serialize(),
          columns: serializeDataSourceColumns(state.dataSource.columns)
        }
      : undefined,
    dataSourceStaticData: state.dataSourceStaticData,
    dataSourceParams: state.dataSourceParams,
    bounds: state.bounds,
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    // sort: state.sort,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet,
    locationStorage: state.locationStorage,
    locationField: state.locationField,
    locationLatitudeField: state.locationLatitudeField,
    locationLongitudeField: state.locationLongitudeField
  };
}

function getListStateFetchNewParams(state: MapState): Object {
  return {
    bounds: state.bounds,
    params: pickBy(state.dataSourceParams, (v, k) => PAGE_PARAM != k),
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    sort: state.sort
  };
}

function getListStateFetchNewQuerySet(state: MapState): Object {
  return {
    params: pickBy(state.dataSourceParams, (v, k) => PAGE_PARAM != k),
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    sort: state.sort
  };
}

function getListStateColumns(state: MapState): Object {
  return {
    columns: state.dataSource ? state.dataSource.columns : undefined
  };
}

function getListStateContextInputs(state: MapState): Object {
  return {
    locationInput: state.locationInput ? state.locationInput.serialize() : undefined,
    zoomInput: state.zoomInput ? state.zoomInput.serialize() : undefined
  };
}

function getListStateMarkers(state: MapState): Object {
  return {
    markerColor: state.settings ? state.settings.markerColor : undefined,
    markerSize: state.settings ? state.settings.markerSize : undefined,
    theme: state.theme
  };
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  providers: [ColumnsModelListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent extends ListLayoutComponent<MapSettings, MapState> implements OnInit, OnDestroy, OnChanges {
  @ViewChild('container') container: ElementRef;
  @ViewChild(AgmMap) map: AgmMap;
  @ViewChild(AgmMarkerCluster) markerCluster: AgmMarkerCluster;

  layout = ListLayoutType.Map;
  visibleColumns: DisplayField[] = [];
  configured = true;
  latitude: number;
  longitude: number;
  zoom: number;
  viewportZoom = 4;
  boundsChanged$ = new Subject<LatLngBounds>();
  fetchBounds$ = new BehaviorSubject<LatLngBoundsLiteral>(undefined);
  markers: MapMarker[] = [];
  openedWindowIndex: number;
  mapStyles: MapTypeStyle[] = [];
  markersClusterStyles: ClusterStyle[];
  fetchSubscription: Subscription;
  contextSubscription: Subscription;

  constructor(
    private modelService: ModelService,
    public customViewsStore: CustomViewsStore,
    private themeService: ThemeService,
    public listStore: ColumnsModelListStore,
    private modelUtilsService: ModelUtilsService,
    public customizeService: CustomizeService,
    private notificationService: NotificationService,
    private zone: NgZone,
    private appConfigService: AppConfigService,
    injector: Injector,
    cd: ChangeDetectorRef,
    customizeBarContext: CustomizeBarContext,
    customizeBarService: CustomizeBarService,
    analyticsService: UniversalAnalyticsService,
    viewSettingsService: ViewSettingsService,
    viewSettingsStore: ViewSettingsStore,
    menuSettingsStore: MenuSettingsStore,
    modelDescriptionStore: ModelDescriptionStore,
    inputService: InputService,
    routing: RoutingService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    popupService: PopupService,
    popoverService: PopoverService
  ) {
    super(
      injector,
      cd,
      customizeBarContext,
      customizeBarService,
      analyticsService,
      viewSettingsService,
      viewSettingsStore,
      menuSettingsStore,
      modelDescriptionStore,
      inputService,
      routing,
      currentProjectStore,
      currentEnvironmentStore,
      popupService,
      popoverService
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.initContext();
    this.boundsChanged$.pipe(debounceTime(300), untilDestroyed(this)).subscribe(value => this.onBoundsChanged(value));

    if (localStorage[this.mapLastPositionKey]) {
      const position = JSON.parse(localStorage[this.mapLastPositionKey]);
      this.latitude = position['latitude'];
      this.longitude = position['longitude'];
      this.viewportZoom = position['zoom'];
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  getListState(
    settings: MapSettings,
    params: Object,
    filters: FilterItem2[],
    search: string,
    sort: Sort[]
  ): Observable<MapState> {
    params = cloneDeep(params);

    delete params[ORDER_BY_PARAM];

    const staticData$ =
      settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
        ? applyParamInput$<Object[]>(settings.dataSource.input, {
            context: this.context,
            defaultValue: [],
            handleLoading: true,
            ignoreEmpty: true
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of([]);
    const inputParams$ = settings.dataSource
      ? applyParamInputs$({}, settings.dataSource.queryInputs, {
          context: this.context,
          parameters: settings.dataSource.queryParameters,
          handleLoading: true,
          ignoreEmpty: true,
          emptyValues: EMPTY_FILTER_VALUES
        }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
      : of({});

    return combineLatest(
      staticData$,
      inputParams$,
      this.getQueryModelDescription(settings.dataSource),
      this.themeService.theme$,
      this.fetchBounds$
    ).pipe(
      map(([staticData, inputParams, modelDescription, theme, bounds]) => {
        const resource = settings.dataSource
          ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == settings.dataSource.queryResource)
          : undefined;

        return {
          settings: settings,
          dataSource: settings.dataSource,
          dataSourceStaticData: staticData,
          dataSourceParams: {
            ...inputParams,
            ...params
          },
          userParams: params,
          filters: filters,
          search: search,
          sort: sort,
          resource: resource,
          modelDescription: modelDescription,
          inputsLoading: [inputParams, staticData].some(obj => {
            return obj == LOADING_VALUE || values(obj).some(item => item === LOADING_VALUE);
          }),
          inputsNotSet: [inputParams, staticData].some(obj => {
            return obj == NOT_SET_VALUE || values(obj).some(item => item === NOT_SET_VALUE);
          }),
          locationStorage: settings ? settings.locationStorage : undefined,
          locationField: settings ? settings.locationField : undefined,
          locationLatitudeField: settings ? settings.locationLatitudeField : undefined,
          locationLongitudeField: settings ? settings.locationLongitudeField : undefined,
          locationInput: settings ? settings.locationInput : undefined,
          zoomInput: settings ? settings.zoomInput : undefined,
          bounds: bounds,
          theme: theme
        };
      })
    );
  }

  onStateUpdated(state: MapState) {
    if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
      this.updateContextOutputs(state);
      this.updateVisibleColumns(state);
    }

    if (!isEqual(getListStateContextInputs(state), getListStateContextInputs(this.listState))) {
      this.initContextObserver(state);
    }

    if (!isEqual(getListStateMarkers(state), getListStateMarkers(this.listState))) {
      this.updateMapStyles(state);
    }

    if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
      const newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
      const newQuerySet = !isEqual(getListStateFetchNewQuerySet(state), getListStateFetchNewQuerySet(this.listState));
      let paramsNeedUpdate = false;

      if (newParams && this.setPage(1)) {
        paramsNeedUpdate = true;
      }

      if (!paramsNeedUpdate) {
        this.fetch(state, newQuerySet);
      }
    } else {
      if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
        if (this.listStore.dataSource) {
          this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
          this.listStore.deserializeModelAttributes();
        }
      }
    }
  }

  fetch(state: MapState, newQuerySet = false) {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
      this.fetchSubscription = undefined;
    }

    this.configured = state.dataSource && state.dataSource.isConfigured() && state.settings.isConfigured();
    this.parameters = this.getParameters(state);
    this.inputs = this.getInputs(state);
    this.cd.markForCheck();

    this.contextElement.patchOutputValueMeta(EMPTY_OUTPUT, { loading: true });

    if (!this.configured) {
      this.listStore.dataSource = undefined;
      this.listStore.params = {};
      this.listStore.queryOptions = undefined;
      this.cd.markForCheck();
      this.listStore.reset();
      return;
    }

    if (state.inputsNotSet) {
      this.listStore.reset();
      this.markers = [];
      this.cd.markForCheck();
      this.listStore.reset();
      return;
    }

    // this.loading = true;
    // this.cd.markForCheck();

    if (state.inputsLoading) {
      this.listStore.reset();
      return;
    }

    const queryOptions = paramsToGetQueryOptions(state.dataSourceParams);

    queryOptions.filters = [...queryOptions.filters, ...state.filters, ...this.boundsToFilters(state.bounds)];
    queryOptions.search = state.search;
    queryOptions.sort = state.sort;

    this.listStore.dataSource = state.dataSource;
    this.listStore.useDataSourceColumns = true;
    this.listStore.staticData = state.dataSourceStaticData;
    this.listStore.queryOptions = queryOptions;
    this.listStore.context = this.context;
    this.listStore.contextElement = this.contextElement;
    this.listStore.reset();

    this.fetchSubscription = this.listStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          let primaryKey;

          if (this.openedWindowIndex != undefined && this.markers[this.openedWindowIndex]) {
            primaryKey = this.markers[this.openedWindowIndex].item.model.primaryKey;
          }

          this.markers = [];
          const index =
            primaryKey != undefined ? result.findIndex(item => item.model.primaryKey == primaryKey) : undefined;
          this.openWindow(index);
          this.appendMarkers(result, newQuerySet);

          this.contextElement.setOutputValue(EMPTY_OUTPUT, result ? !result.length : false, { loading: false });
        },
        error => {
          let message;

          if (error instanceof ServerRequestError && error.errors.length) {
            message = error.errors[0];
          } else if (error.hasOwnProperty('message')) {
            console.error(error);
            message = error.message;
          } else {
            console.error(error);
            message = error;
          }

          this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });

          this.notificationService.error('Loading failed', message);
        }
      );
  }

  initContext() {
    this.contextElement.setActions([
      {
        uniqueName: 'update_data',
        name: 'Update Data',
        icon: 'repeat',
        parameters: [],
        handler: () => this.reloadData()
      },
      {
        uniqueName: 'clear_selected_item',
        name: 'Reset Selected Marker',
        icon: 'deselect',
        parameters: [],
        handler: () => this.closeWindow()
      },
      {
        uniqueName: 'clear_filters',
        name: 'Reset Filters',
        icon: 'delete',
        parameters: [],
        handler: () => this.resetFilters()
      },
      {
        uniqueName: 'fit_markers',
        name: 'Fit markers in viewport',
        icon: 'enlarge_expand',
        parameters: [],
        handler: () => this.fitMarkers()
      },
      {
        uniqueName: 'set_center',
        name: 'Set viewport center',
        icon: 'target',
        parameters: [
          new ParameterField({
            name: 'latitude',
            field: FieldType.Number,
            params: {
              value_format: {
                number_fraction: 10
              }
            }
          }),
          new ParameterField({
            name: 'longitude',
            field: FieldType.Number,
            params: {
              value_format: {
                number_fraction: 10
              }
            }
          }),
          new ParameterField({
            name: 'zoom',
            field: FieldType.Number,
            required: false
          })
        ],
        handler: params => this.setLocation(params['latitude'], params['longitude'], params['zoom'])
      },
      {
        uniqueName: 'set_zoom',
        name: 'Set viewport zoom',
        icon: 'search',
        parameters: [
          new ParameterField({
            name: 'zoom',
            field: FieldType.Number
          })
        ],
        handler: params => this.setZoom(params['zoom'])
      }
    ]);
  }

  initContextObserver(state: MapState) {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    const location$ = state.locationInput
      ? applyParamInput$(state.locationInput, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);
    const zoom$ = state.zoomInput
      ? applyParamInput$(state.zoomInput, {
          context: this.context,
          defaultValue: null
        }).pipe(distinctUntilChanged())
      : of(null);

    this.contextSubscription = combineLatest(
      location$.pipe(
        map(value => {
          if (!value) {
            return;
          }

          const latitude = firstSet(value['lat'], value['latitude']);
          const longitude = firstSet(value['lng'], value['longitude']);

          return isSet(latitude) && longitude ? { latitude: latitude, longitude: longitude } : undefined;
        }),
        filter(value => isSet(value)),
        tap(value => this.setLocation(value['latitude'], value['longitude']))
      ),
      zoom$.pipe(
        filter(value => isSet(value)),
        tap(value => this.setZoom(value))
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  updateContextOutputs(state: MapState) {
    const columns = state.dataSource ? state.dataSource.columns : [];

    this.contextElement.setOutputs([
      {
        uniqueName: ITEM_OUTPUT,
        name: 'Current Marker',
        icon: 'duplicate_2',
        internal: true,
        byPathOnly: true,
        allowSkip: true,
        children: rawListViewSettingsColumnsToViewContextOutputs(
          columns.filter(item => item.type != DisplayFieldType.Computed),
          state.modelDescription
        )
      },
      {
        uniqueName: SELECTED_ITEM_OUTPUT,
        name: 'Selected Marker',
        icon: 'hand',
        children: rawListViewSettingsColumnsToViewContextOutputs(columns, state.modelDescription)
      },
      {
        uniqueName: HAS_SELECTED_ITEM_OUTPUT,
        name: 'Is any Marker selected',
        icon: 'select_all',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      {
        uniqueName: NO_SELECTED_ITEM_OUTPUT,
        name: 'No Marker selected',
        icon: 'deselect',
        fieldType: FieldType.Boolean,
        defaultValue: true
      },
      {
        uniqueName: EMPTY_OUTPUT,
        name: 'Is Empty',
        icon: 'uncheck',
        fieldType: FieldType.Boolean,
        defaultValue: false
      },
      {
        uniqueName: CENTER_LOCATION_OUTPUT,
        name: 'Viewport center location',
        icon: 'target',
        fieldType: FieldType.Location
      },
      {
        uniqueName: TOP_LEFT_LOCATION_OUTPUT,
        name: 'Viewport top left location',
        icon: 'corner_top_left',
        fieldType: FieldType.Location
      },
      {
        uniqueName: TOP_RIGHT_LOCATION_OUTPUT,
        name: 'Viewport top right location',
        icon: 'corner_top_right',
        fieldType: FieldType.Location
      },
      {
        uniqueName: BOTTOM_RIGHT_LOCATION_OUTPUT,
        name: 'Viewport bottom right location',
        icon: 'corner_bottom_right',
        fieldType: FieldType.Location
      },
      {
        uniqueName: BOTTOM_LEFT_LOCATION_OUTPUT,
        name: 'Viewport bottom left location',
        icon: 'corner_bottom_left',
        fieldType: FieldType.Location
      },
      {
        uniqueName: ZOOM_OUTPUT,
        name: 'Viewport zoom',
        icon: 'search',
        fieldType: FieldType.Number
      }
    ]);
  }

  updateVisibleColumns(state: MapState) {
    this.visibleColumns = state.dataSource.columns.filter(item => item.visible);
    this.cd.markForCheck();
  }

  updateMapStyles(state: MapState) {
    this.mapStyles = this.getMapStyles(state);
    this.markersClusterStyles = this.getMarkersClusterStyles(state);
    this.zone.onStable.pipe(first(), untilDestroyed(this)).subscribe(() => this.repaintMarkerClusters());
  }

  repaintMarkerClusters() {
    if (!this.markerCluster) {
      return;
    }

    this.markerCluster['_clusterManager'].getClustererInstance().then(clusterer => {
      clusterer.repaint();
    });
  }

  getMapStyles(state: MapState): MapTypeStyle[] {
    return state.theme == 'dark' ? MapDarkStyles : MapStyles;
  }

  getMarkersClusterStyles(state: MapState): ClusterStyle[] {
    const colorHex = isColorHex(this.settings.markerColor)
      ? this.settings.markerColor.substring(1)
      : colors.filter(item => item.name == this.settings.markerColor).map(item => numberToHex(item.hex))[0];
    const clr = colorHex ? parseColor('#' + colorHex) : undefined;
    const isDark = !clr || clr.contrast(Color('white')) >= 2;
    const size = this.settings.markerSize ? this.settings.markerSize * 2 : 52;
    const textSize = clamp(12, 8, size * 0.6);
    const textColor = !clr || isDark ? '#fff' : clr.darken(0.8).string();
    const url = new URL(`${this.appConfigService.serverBaseUrl}/assets/marker-cluster.svg`);

    if (colorHex) {
      url.searchParams.append('color', colorHex);
    }

    if (state.theme == 'dark') {
      url.searchParams.append('dark', '1');
    }

    if (size) {
      url.searchParams.append('size', size.toString());
    }

    return [
      {
        url: url.toString(),
        height: size,
        width: size,
        textSize: textSize,
        textColor: textColor
      }
    ];
  }

  get mapLastPositionKey() {
    return `map_last_position_${this.viewId}`;
  }

  openWindow(index: number) {
    if (this.openedWindowIndex == index) {
      return;
    }

    this.openedWindowIndex = index;
    this.cd.markForCheck();
    this.updateSelectedContext();
  }

  closeWindow(index?: number) {
    if (index !== undefined && index !== this.openedWindowIndex) {
      return;
    }

    this.openedWindowIndex = undefined;
    this.cd.markForCheck();
    this.updateSelectedContext();
  }

  isInfoWindowOpen(index) {
    return this.openedWindowIndex === index;
  }

  updateSelectedContext() {
    const model =
      this.openedWindowIndex != undefined && this.markers[this.openedWindowIndex]
        ? this.markers[this.openedWindowIndex].item.model
        : undefined;

    if (model) {
      const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
      this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, getModelAttributesByColumns(model, columns));
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
    } else {
      this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
      this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
      this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
    }
  }

  appendMarkers(items: ListItem[], newQuerySet = false) {
    const markers: MapMarker[] = items
      .map(item => {
        if ([MapLocationStorage.PostgreSQL, MapLocationStorage.Object].includes(this.settings.locationStorage)) {
          const location = item.model.getAttribute(this.settings.locationField);
          if (location && location['lat'] && location['lng']) {
            return {
              latitude: parseNumber(location['lat']),
              longitude: parseNumber(location['lng']),
              title: this.modelUtilsService.str(item.model),
              item: item
            };
          }
        } else if (this.settings.locationStorage == MapLocationStorage.TwoFields) {
          const latitudeLocation = item.model.getAttribute(this.settings.locationLatitudeField);
          const longitudeLocation = item.model.getAttribute(this.settings.locationLongitudeField);

          return {
            latitude: parseNumber(latitudeLocation),
            longitude: parseNumber(longitudeLocation),
            title: this.modelUtilsService.str(item.model),
            item: item
          };
        }
      })
      .filter(item => item && isSet(item.latitude) && isSet(item.longitude));

    this.markers = [...this.markers, ...markers];
    this.cd.markForCheck();

    if (!isSet(this.latitude) || (this.settings.markersFitOnChange && newQuerySet)) {
      this.fitMarkers();
    }
  }

  fitMarkers() {
    if (!this.markers.length) {
      return;
    }

    const markersCoords = this.markers.map(item => {
      return {
        lat: item.latitude,
        lng: item.longitude
      };
    });

    const center = coordinatesCenter(markersCoords);
    const bounds = coordinatesBounds(markersCoords);
    const padding = 20;
    const zoom = bounds
      ? getBoundsZoomLevel(
          bounds,
          this.container.nativeElement.offsetWidth - padding * 2,
          this.container.nativeElement.offsetHeight - padding * 2
        )
      : 4;

    this.setLocation(center.latitude, center.longitude, zoom);
  }

  setLocation(latitude: number, longitude: number, zoom?: number) {
    if (isSet(this.latitude) || isSet(this.longitude) || isSet(this.zoom)) {
      this.latitude = undefined;
      this.longitude = undefined;

      if (isSet(zoom)) {
        this.zoom = undefined;
        this.viewportZoom = undefined;
      }

      this.cd.detectChanges();
    }

    this.latitude = latitude;
    this.longitude = longitude;

    if (isSet(zoom)) {
      this.zoom = zoom;
      this.viewportZoom = this.zoom;
    }

    this.cd.markForCheck();
  }

  setZoom(zoom: number) {
    if (isSet(this.zoom)) {
      this.zoom = undefined;
      this.viewportZoom = undefined;
      this.cd.detectChanges();
    }

    this.zoom = zoom;
    this.viewportZoom = this.zoom;
    this.cd.markForCheck();
  }

  boundsToFilters(bounds: LatLngBoundsLiteral): FilterItem2[] {
    if (!bounds || isNaN(bounds.south)) {
      return [];
    }

    if (this.settings.locationStorage == MapLocationStorage.PostgreSQL) {
      const field = this.settings.dataSource.columns.find(item => item.name == this.settings.locationField);
      const boundsArray =
        field && field.params['inverted_coordinates']
          ? [
              [bounds.north, bounds.west],
              [bounds.north, bounds.east],
              [bounds.south, bounds.east],
              [bounds.south, bounds.west],
              [bounds.north, bounds.west]
            ]
          : [
              [bounds.west, bounds.north],
              [bounds.east, bounds.north],
              [bounds.east, bounds.south],
              [bounds.west, bounds.south],
              [bounds.west, bounds.north]
            ];
      const polygonArgs = boundsArray.map(item => item.join(' ')).join(',');

      return [
        new FilterItem2({
          field: [this.settings.locationField],
          lookup: coveredByFieldLookup,
          value: `POLYGON((${polygonArgs}))`
        })
      ];
    } else if (this.settings.locationStorage == MapLocationStorage.TwoFields) {
      return [
        new FilterItem2({
          field: [this.settings.locationLatitudeField],
          lookup: gteFieldLookup,
          value: bounds.south
        }),
        new FilterItem2({
          field: [this.settings.locationLatitudeField],
          lookup: lteFieldLookup,
          value: bounds.north
        }),
        new FilterItem2({
          field: [this.settings.locationLongitudeField],
          lookup: gteFieldLookup,
          value: bounds.west
        }),
        new FilterItem2({
          field: [this.settings.locationLongitudeField],
          lookup: lteFieldLookup,
          value: bounds.east
        })
      ];
    } else if (this.settings.locationStorage == MapLocationStorage.Object) {
      return [
        new FilterItem2({
          field: [`${this.settings.locationField}_latitude`],
          lookup: gteFieldLookup,
          value: bounds.south
        }),
        new FilterItem2({
          field: [`${this.settings.locationField}_latitude`],
          lookup: lteFieldLookup,
          value: bounds.north
        }),
        new FilterItem2({
          field: [`${this.settings.locationField}_longitude`],
          lookup: gteFieldLookup,
          value: bounds.west
        }),
        new FilterItem2({
          field: [`${this.settings.locationField}_longitude`],
          lookup: lteFieldLookup,
          value: bounds.east
        })
      ];
    }
  }

  onModelUpdated(model: Model) {
    const openedMarker = this.markers[this.openedWindowIndex];

    if (openedMarker && openedMarker.item.model.isSame(model)) {
      this.updateSelectedContext();
    }
  }

  trackByFn(index, item: MapMarker) {
    return item.item.model.primaryKey;
  }

  onBoundsChanged(googleBounds: LatLngBounds) {
    if (!this.map || !googleBounds) {
      return;
    }

    const currentBounds = this.fetchBounds$.value;
    const bounds = googleBounds.toJSON();
    const center = coordinatesCenter([
      { lat: bounds.north, lng: bounds.west },
      { lat: bounds.south, lng: bounds.east }
    ]);

    this.contextElement.setOutputValue(CENTER_LOCATION_OUTPUT, { lat: center.latitude, lng: center.longitude });
    this.contextElement.setOutputValue(TOP_LEFT_LOCATION_OUTPUT, { lat: bounds.north, lng: bounds.west });
    this.contextElement.setOutputValue(TOP_RIGHT_LOCATION_OUTPUT, { lat: bounds.north, lng: bounds.east });
    this.contextElement.setOutputValue(BOTTOM_RIGHT_LOCATION_OUTPUT, { lat: bounds.south, lng: bounds.east });
    this.contextElement.setOutputValue(BOTTOM_LEFT_LOCATION_OUTPUT, { lat: bounds.south, lng: bounds.west });
    this.contextElement.setOutputValue(ZOOM_OUTPUT, this.map.zoom);

    if (this.zoom == this.map.zoom && currentBounds && containsBounds(currentBounds, bounds)) {
      return;
    }

    const paddingVertical = (bounds.north - bounds.south) * 0.1;
    const paddingHorizontal = (bounds.east - bounds.west) * 0.1;

    bounds.east += paddingHorizontal;
    bounds.north += paddingVertical;
    bounds.south -= paddingVertical;
    bounds.west -= paddingHorizontal;

    this.zoom = this.map.zoom;

    center['zoom'] = this.map.zoom;
    localStorage[this.mapLastPositionKey] = JSON.stringify(center);

    this.fetchBounds$.next(bounds);
  }

  public getAnyModel(): Model {
    if (!this.listStore.items || !this.listStore.items.length) {
      return;
    }

    return this.listStore.items[0].model;
  }
}
