<div class="popup">
  <app-popup2 [size]="'sm'">
    <app-popup2-header>
      <app-popup2-title *ngIf="sync">Enable sync mode for {{ object }}</app-popup2-title>
      <app-popup2-title *ngIf="!sync">Use direct connection for {{ object }}</app-popup2-title>
    </app-popup2-header>

    <app-page-block>
      <div class="field">
        <div
          *ngIf="sync"
          class="button-selector-item button-selector-item_color_orange"
          [class.button-selector-item_active]="false"
        >
          <app-sync-mode-image class="button-selector-item__image-block" [typeItem]="typeItem"></app-sync-mode-image>
          <div class="button-selector-item__tag background-color_orange_1">
            <span class="button-selector-item__tag-raw">β</span>eta
          </div>
          <div class="button-selector-item__list">
            <div class="button-selector-item__list-item">
              <div class="button-selector-item__list-item-left">
                <span
                  class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                ></span>
              </div>
              <div class="button-selector-item__list-item-main">
                Allow <strong>SQL queries</strong> with {{ typeItem?.label }}
              </div>
            </div>
            <div class="button-selector-item__list-item">
              <div class="button-selector-item__list-item-left">
                <span
                  class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                ></span>
              </div>
              <div class="button-selector-item__list-item-main">
                <strong>Blend and join</strong> data from over 30 data sources
              </div>
            </div>
            <div
              *ngIf="
                [
                  resourceNames.Airtable,
                  resourceNames.SmartSuite,
                  resourceNames.Firebase,
                  resourceNames.GoogleSheets
                ].includes($any(typeItem.name))
              "
              class="button-selector-item__list-item"
            >
              <div class="button-selector-item__list-item-left">
                <span
                  class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                ></span>
              </div>
              <div class="button-selector-item__list-item-main">
                Suitable for <strong>large volume data</strong> (100k+ records)
              </div>
            </div>
            <div class="button-selector-item__list-item">
              <div class="button-selector-item__list-item-left">
                <span
                  class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                ></span>
              </div>
              <div class="button-selector-item__list-item-main">
                Data is synced to <strong>Jet Tables</strong>
                <ng-container *ngIf="[resourceNames.SmartSuite].includes($any(typeItem.name))">
                  every 15 minutes
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div
          *ngIf="!sync"
          class="button-selector-item button-selector-item_color_teal-blue"
          [class.button-selector-item_active]="false"
        >
          <app-direct-mode-image
            class="button-selector-item__image-block"
            [typeItem]="typeItem"
          ></app-direct-mode-image>
          <!--            <div class="button-selector-item__tag button-selector-item__tag_position_top background-color_blue_1">-->
          <!--              has limitations-->
          <!--            </div>-->
          <div class="button-selector-item__list">
            <div class="button-selector-item__list-item">
              <div class="button-selector-item__list-item-left">
                <span class="button-selector-item__list-circle button-selector-item__list-circle_red icon-close"></span>
              </div>
              <div class="button-selector-item__list-item-main">
                No <strong>SQL queries</strong> support with {{ typeItem?.label }}
              </div>
            </div>
            <div class="button-selector-item__list-item">
              <div class="button-selector-item__list-item-left">
                <span class="button-selector-item__list-circle button-selector-item__list-circle_red icon-close"></span>
              </div>
              <div class="button-selector-item__list-item-main">No <strong>Blend and join</strong> data support</div>
            </div>
            <div
              *ngIf="[resourceNames.Airtable, resourceNames.SmartSuite].includes($any(typeItem.name))"
              class="button-selector-item__list-item"
            >
              <div class="button-selector-item__list-item-left">
                <span class="button-selector-item__list-circle button-selector-item__list-circle_red icon-close"></span>
              </div>
              <div class="button-selector-item__list-item-main">
                Charts not supported
              </div>
            </div>
            <div
              *ngIf="
                [
                  resourceNames.Airtable,
                  resourceNames.SmartSuite,
                  resourceNames.Firebase,
                  resourceNames.GoogleSheets
                ].includes($any(typeItem.name))
              "
              class="button-selector-item__list-item"
            >
              <div class="button-selector-item__list-item-left">
                <span class="button-selector-item__list-circle button-selector-item__list-circle_red icon-close"></span>
              </div>
              <div class="button-selector-item__list-item-main">
                Not suitable for <strong>large volume data</strong> (100k+ records)
              </div>
            </div>
            <div class="button-selector-item__list-item">
              <div class="button-selector-item__list-item-left">
                <span
                  class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                ></span>
              </div>
              <div class="button-selector-item__list-item-main">
                No sync delays
              </div>
            </div>
            <div class="button-selector-item__list-item">
              <div class="button-selector-item__list-item-left">
                <span
                  class="button-selector-item__list-circle button-selector-item__list-circle_green icon-check_2"
                ></span>
              </div>
              <div class="button-selector-item__list-item-main">
                <strong>Direct requests</strong> to {{ typeItem?.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-page-block>

    <app-page-block *ngIf="sync && isPublishRequired && hasDraftChanges">
      <app-alert [purple]="true" [title]="'This resource has unpublished changes'" [icon]="'warning_filled'">
        Please note that Sync uses only published resource credentials
      </app-alert>
    </app-page-block>

    <app-popup2-footer>
      <app-page-block>
        <app-stack [align]="'right'">
          <button type="button" class="button popup-content__submit-item" [disabled]="loading" (click)="close()">
            Cancel
          </button>

          <button
            type="button"
            class="button button_primary popup-content__submit-item"
            [disabled]="loading"
            (click)="submit()"
          >
            <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>

            <span *ngIf="sync" class="button__label">Enable Sync</span>
            <span *ngIf="!sync" class="button__label">Use direct connection</span>
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</div>
