<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup" [class.resource-popup_wide]="form.form.value['choose_tables']">
    <div
      *ngIf="environment"
      class="resource-popup__tag"
      [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
    >
      {{ environment.name }}
    </div>
    <div class="resource-popup__image">
      <div
        class="resource-popup__image-inner"
        [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
      ></div>
    </div>
    <div class="resource-popup__title">
      Allow Jet Admin to access <br />
      <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
    </div>
    <div class="resource-popup__description">
      Enter your API Key to create internal tools on top of your data.
    </div>

    <div class="resource-popup__content">
      <ng-container *ngIf="!form.form.value['choose_tables']">
        <app-page-block *ngIf="resourceNameEditing">
          <app-auto-field
            [form]="form.resourceForm"
            [field]="
              createField({
                name: 'name',
                label: 'Resource Name',
                field: 'CharField',
                description: 'This is how you and your team will see it',
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block *ngIf="form.legacyOptions">
          <app-alert [title]="'Credentials you are using are now deprecated'" [warning]="true">
            <div>
              Please specify new Airtable credentials below to update your resource integration. We recommend to
              download App backup before moving to new credentials.
            </div>
          </app-alert>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">Authentication</div>
            <div class="field__value">
              <div class="sidebar-icon-buttons sidebar-icon-buttons_contrast sidebar-icon-buttons_border">
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="form.form.controls['auth_type'].value == authTypes.OAuth"
                  (click)="form.form.controls['auth_type'].setValue(authTypes.OAuth)"
                >
                  <div class="sidebar-icon-button__label">OAuth</div>
                </div>
                <div
                  class="sidebar-icon-button"
                  [class.sidebar-icon-button_active]="
                    form.form.controls['auth_type'].value == authTypes.PersonalAccessToken
                  "
                  (click)="form.form.controls['auth_type'].setValue(authTypes.PersonalAccessToken)"
                >
                  <div class="sidebar-icon-button__label">Personal access token</div>
                </div>
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block *ngIf="form.form.controls['auth_type'].value == authTypes.OAuth">
          <div *ngIf="accountLoading" class="field">
            <div class="field__label">Airtable Profile</div>
            <div class="field__value">
              <div class="input input_fill">
                <span [class.loading-animation]="true"><span class="stub-text">user@example.com</span></span>
              </div>
            </div>
          </div>
          <div *ngIf="!accountLoading" class="field">
            <div *ngIf="account" class="field__label">
              <span class="field__label-text">Airtable Profile</span>
              <span class="field__label-right">
                <a href="javascript:void(0)" class="field__label-link" (click)="getOAuthToken()">
                  <span class="field__label-link-icon icon-repeat"></span>
                  Refresh Airtable token
                </a>
              </span>
            </div>
            <div *ngIf="account" class="field__value">
              <div class="input input_fill">{{ account }}</div>
            </div>
            <div *ngIf="!account" class="field__value">
              <button type="button" class="button button_primary button_fill" (click)="getOAuthToken()">
                <span class="button__label">Sign In to Airtable</span>
              </button>
            </div>
            <div class="field__description">
              Airtable is a secure partner with Jet Admin. Your credentials are stored on Airtable side.
            </div>
          </div>
        </app-page-block>

        <app-page-block *ngIf="form.form.controls['auth_type'].value == authTypes.PersonalAccessToken">
          <app-auto-field
            [form]="form.form"
            [field]="
              createField({
                name: 'personal_access_token',
                label: 'Personal access token',
                field: 'CharField',
                placeholder: defaultPlaceholder,
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">
              base
            </div>
            <div class="field__value">
              <app-select
                *ngIf="form.isAuthValid() && (baseSource.accessToken | appIsSet)"
                [placeholder]="'choose' | localize | appCapitalize"
                [control]="form.form.controls['base']"
                [fill]="true"
                [source]="baseSource"
                [searchDebounce]="200"
              >
              </app-select>
              <div
                *ngIf="!form.isAuthValid() || !(baseSource.accessToken | appIsSet)"
                class="input input_fill input_disabled"
              >
                <ng-container *ngIf="!form.form.controls['access_token'].pending">
                  Connect Airtable account first
                </ng-container>
                <span *ngIf="form.form.controls['access_token'].pending" [class.loading-animation]="true">
                  <span class="stub-text">Base 1</span>
                </span>
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <a [appDocumentation]="'integrations/airtable'" class="button button_small" style="margin: 0;">
            Read documentation
          </a>
        </app-page-block>

        <!--        <app-page-block>-->
        <!--          <ng-template #tip>-->
        <!--            <ul style="margin: 0; padding: 0 0 0 10px;">-->
        <!--              <li>Working with large amounts of data can be slow</li>-->
        <!--              <li>Not possible to run SQL queries</li>-->
        <!--              <li>Not possible to write JOIN queries with other resources</li>-->
        <!--              <li>Sync is charged upon number of queries</li>-->
        <!--            </ul>-->
        <!--          </ng-template>-->

        <!--          <div class="toggle-button" (click)="form.form.controls['sync'].setValue(!form.form.controls['sync'].value)">-->
        <!--            <div class="toggle-button__left">-->
        <!--              <app-toggle [orange]="true" [selected]="!form.form.controls['sync'].value"> </app-toggle>-->
        <!--            </div>-->
        <!--            <div class="toggle-button__main">-->
        <!--              <div class="toggle-button__title">Work directly with {{ typeItem.label }}</div>-->
        <!--              <div class="toggle-button__subtitle">-->
        <!--                Has fetch data limitations-->

        <!--                <a-->
        <!--                  class="help-icon help-icon_clickable icon-help"-->
        <!--                  [appTip]="tip"-->
        <!--                  [appTipOptions]="{ side: 'top' }"-->
        <!--                ></a>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </app-page-block>-->
      </ng-container>

      <ng-container *ngIf="form.form.value['choose_tables']">
        <app-page-block>
          <table class="card-table card-table_compact">
            <tbody>
              <tr *ngFor="let control of form.tables.controls" class="card-table__row card-table__row_contrast2">
                <td class="card-table__column" [style.width.%]="40">
                  <div class="card-table__column-inner">
                    <div
                      class="table-field table-field_grow"
                      [class.table-field_disabled]="!control.controls.active.value"
                    >
                      <div class="table-field__control">
                        <app-toggle
                          [orange]="true"
                          [selected]="control.controls.active.value"
                          (click)="
                            control.controls.active.patchValue(!control.controls.active.value); $event.stopPropagation()
                          "
                        >
                        </app-toggle>
                      </div>

                      <div class="table-field__info">
                        <div class="table-field__title">
                          {{ control.table.name }}
                        </div>
                        <div class="table-field__subtitle">
                          {{ control.table.fields.length | i18nPlural: { '=1': '# field', other: '# fields' } }}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <td class="card-table__column" [style.width.px]="40">
                  <div class="card-table__column-inner">
                    <span class="card-table__grey icon-arrow_forward" [style.font-size.px]="20"></span>
                  </div>
                </td>

                <td class="card-table__column">
                  <div class="card-table__column-inner">
                    <app-select
                      [control]="control.controls.view"
                      [placeholder]="'Choose view'"
                      [options]="control.viewOptions"
                      [fill]="true"
                      [small]="true"
                      [style.width.%]="100"
                    ></app-select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </app-page-block>
      </ng-container>

      <app-page-block *ngIf="(form.form | appFormErrors).length">
        <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
      </app-page-block>
    </div>

    <app-alert
      *ngIf="slowLoading"
      [title]="'Import in progress'"
      [warning]="true"
      [animateShow]="true"
      class="popup-content__content-alert"
    >
      <p>
        It can take 2 minutes or more depending on the size of base
      </p>
    </app-alert>

    <div class="popup-content__submit" [class.popup-content__submit_no-border]="slowLoading">
      <button
        *ngIf="resourceDelete && resource"
        type="button"
        class="button button_danger popup-content__delete"
        (click)="requestDelete()"
      >
        Delete
      </button>

      <button type="button" class="button popup-content__submit-item" (click)="cancel()">
        Cancel
      </button>

      <button
        *ngIf="!form.form.value['choose_tables']"
        type="button"
        class="button button_primary popup-content__submit-item"
        [class.button_disabled]="!form.isBaseValid() || accountLoading || chooseTablesLoading || loadingSubmit"
        (click)="chooseTables()"
      >
        <app-loader-small *ngIf="chooseTablesLoading" class="button__icon button__icon_left"></app-loader-small>
        <span class="button__label">Choose Tables</span>
      </button>

      <button
        *ngIf="form.form.value['choose_tables']"
        type="button"
        class="button popup-content__submit-item"
        (click)="form.form.patchValue({ choose_tables: false })"
      >
        <span class="button__label">Back to Settings</span>
      </button>

      <button
        *ngIf="form.form.value['choose_tables']"
        type="button"
        class="button button_primary popup-content__submit-item"
        [class.button_disabled]="!form.isValid() || loadingSubmit"
        (click)="resource ? submit() : chooseSync()"
      >
        <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
        <ng-container *ngIf="resource">Update resource</ng-container>
        <ng-container *ngIf="!resource">Add resource</ng-container>
      </button>
    </div>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem"></app-resource-settings-stub>
