var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DragAxis, moveItemInFormArray, transferFormArrayItem } from '@common/drag-drop2';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { BODY_CONTENTFUL_RIGHT_BAR_CLASS } from '@modules/sidebar';
import { addClass, removeClass } from '@shared';
import { MenuBlockControl } from '../project-settings/menu-block.control';
import { CustomizeBarPagesEditMenuItemArray } from './customize-bar-pages-edit.form';
var CustomizeBarPagesEditComponent = /** @class */ (function () {
    function CustomizeBarPagesEditComponent(activatedRoute, currentProjectStore, customizeService, routing, analyticsService, cd) {
        this.activatedRoute = activatedRoute;
        this.currentProjectStore = currentProjectStore;
        this.customizeService = customizeService;
        this.routing = routing;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.title = 'Menu editing';
        this.horizontal = false;
        this.dragAxis = DragAxis;
    }
    CustomizeBarPagesEditComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.menuItem.id;
    };
    CustomizeBarPagesEditComponent.prototype.ngOnInit = function () {
        this.updateHorizontal();
        addClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS);
    };
    CustomizeBarPagesEditComponent.prototype.ngOnDestroy = function () {
        removeClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS);
    };
    CustomizeBarPagesEditComponent.prototype.ngOnChanges = function (changes) {
        if (changes.layout) {
            this.updateHorizontal();
        }
    };
    CustomizeBarPagesEditComponent.prototype.updateHorizontal = function () {
        this.horizontal = MenuBlockLayouts.isHorizontal(this.layout);
    };
    CustomizeBarPagesEditComponent.prototype.addMenuItem = function (container, options) {
        this.createdMenuItemControl = container.addControl(options.item, options.prepend);
        this.createdMenuItemControlOptions = options;
        this.cd.markForCheck();
        this.analyticsService.sendEvent(AnalyticsEvent.GA.MenuItem, AnalyticsEventAction.Created);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Menu.MenuItemAdded);
    };
    CustomizeBarPagesEditComponent.prototype.dragDrop = function (event) {
        if (event.previousContainer === event.container) {
            moveItemInFormArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferFormArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    };
    CustomizeBarPagesEditComponent.prototype.back = function () {
        var link = this.currentProjectStore.instance.settingsMenuLink();
        var fromAppSettings = this.activatedRoute.snapshot.queryParams['app_settings'];
        this.routing.navigateApp(link, { queryParams: __assign({}, (fromAppSettings && { app_settings: '1' })) });
    };
    return CustomizeBarPagesEditComponent;
}());
export { CustomizeBarPagesEditComponent };
