import { Overlay } from '@angular/cdk/overlay';
import { CdkPortalOutlet } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as Color from 'color';
import { bisectCenter, extent, least, max, min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { ScaleLinear, scaleLinear, ScalePoint, scalePoint, ScaleTime, scaleTime } from 'd3-scale';
import { pointer, select, Selection } from 'd3-selection';
import { area, curveLinear, curveMonotoneX, line } from 'd3-shape';
import { timeDay, timeHour, timeMinute, timeMonth, timeSecond, timeWeek, timeYear } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as numeral from 'numeral';
import { BehaviorSubject, combineLatest, fromEvent, merge } from 'rxjs';
import { map, skip } from 'rxjs/operators';

import { elementResize$, elementSize$, generateAlphanumeric, isSet, TypedChanges } from '@shared';

// TODO: Refactor import
import { getColorHex, getColorHexStr, parseColor } from '../../../colors/utils/colors';

import { CHART_COLORS } from '../../data/chart-colors';
import { DataGroup } from '../../data/data-group';
import { Dataset, datasetGroupDateLookups, DatasetGroupLookup } from '../../data/dataset';
import { DataClickEvent } from '../../data/events';
import { DataTooltipController } from '../../services/data-tooltip-controller/data-tooltip.controller';
import { fitXAxisLabelWithVisibility, getYAxisWidth } from '../../utils/d3';
import {
  applyDatasetsDefaultColors,
  DataTotalItem,
  getDatasetsGroupLookup,
  getDatasetsGroups,
  getDatasetsUniqueGroups,
  prepareDataset,
  syncSortedDatasetsGroups
} from '../../utils/dataset';
import { getDateFormatByLookup } from '../../utils/date';

@Component({
  selector: 'app-line-chart2',
  templateUrl: './line-chart2.component.html',
  providers: [DataTooltipController],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineChart2Component implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() datasets: Dataset[] = [];
  @Input() smooth = false;
  @Input() yFormat: string;
  @Input() min: number;
  @Input() max: number;
  @Input() animate = true;
  @Input() xAxisVisible = true;
  @Input() yAxisVisible = true;
  @Input() legend = true;
  @Input() interactive = true;
  @Input() tooltips = true;
  @Input() lineWidth: number;
  @Input() lineArea = true;
  @Input() dataClickEnabled = false;
  @Output() groupHover = new EventEmitter<{ group: string; value: number }>();
  @Output() dataClick = new EventEmitter<DataClickEvent>();

  @ViewChild('canvas') canvasElement: ElementRef;
  @ViewChild('svg') svgElement: ElementRef;
  @ViewChild('tooltip_container') tooltipContainerElement: ElementRef;
  @ViewChild(CdkPortalOutlet) portalOutlet: CdkPortalOutlet;

  data: Dataset<number, string | moment.Moment>[] = [];
  dataGroups: (string | moment.Moment)[] = [];
  dataGroupLookup?: DatasetGroupLookup;
  dataTotal: DataTotalItem[] = [];
  margin = { top: 8, right: 8, bottom: 8, left: 8 };
  width: number;
  height: number;
  svg: Selection<SVGGElement, {}, any, {}>;
  xScaleTime: ScaleTime<number, number>;
  xScalePoint: ScalePoint<any>;
  xAxis: Selection<SVGGElement, {}, any, {}>;
  yScale: ScaleLinear<number, number>;
  yAxis: Selection<SVGGElement, {}, any, {}>;
  lineCircle: Selection<SVGCircleElement, {}, any, {}>;
  interactiveRect: Selection<SVGRectElement, {}, any, {}>;
  hoverDatasetIndex$ = new BehaviorSubject<number>(undefined);
  hoverDatasetGroup$ = new BehaviorSubject<{ datasetIndex: number; groupIndex: number }>(undefined);
  hoverLegendDatasetIndex$ = new BehaviorSubject<number>(undefined);
  selectedDatasetIndexes: { [k: number]: boolean };
  colors = CHART_COLORS;
  uid = generateAlphanumeric(8);

  get xScale(): ScaleTime<number, number> | ScalePoint<any> {
    return this.xScaleTime || this.xScalePoint;
  }

  getId(name: string): string {
    return `${name}-${this.uid}`;
  }

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private dataTooltip: DataTooltipController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<LineChart2Component>): void {
    if (changes.datasets) {
      this.data = this.datasets.map(dataset => prepareDataset(dataset));
      this.dataGroupLookup = getDatasetsGroupLookup(this.data);
      this.dataGroups = getDatasetsUniqueGroups(this.data);

      applyDatasetsDefaultColors(this.data, this.colors);
      syncSortedDatasetsGroups(this.data, this.dataGroups);

      this.dataTotal = getDatasetsGroups(this.data);
    }

    if (this.svg) {
      this.rerender();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();

      elementSize$(this.canvasElement.nativeElement)
        .pipe(skip(1), untilDestroyed(this))
        .subscribe(() => this.onResize());
    }, 0);
  }

  init() {
    this.initBounds();
    this.initSvg();
    this.initYAxis();
    this.renderYAxis();
    this.fitYAxis();
    this.initXAxis();
    this.renderXAxis();
    this.renderLine();
    this.renderGradients();
    this.initDatasetHover();
    this.initInteractive();
    this.renderInteractive();
    this.initEvents();
  }

  initBounds() {
    const width = this.canvasElement.nativeElement.offsetWidth;
    const height = this.canvasElement.nativeElement.offsetHeight;

    this.margin = { top: 8, right: 8, bottom: this.xAxisVisible ? 22 : 8, left: this.yAxisVisible ? 40 : 8 };
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;
  }

  initSvg() {
    this.svg = select(this.svgElement.nativeElement)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  initXAxis() {
    this.xAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_x');
  }

  renderXAxis() {
    if (datasetGroupDateLookups.includes(this.dataGroupLookup)) {
      this.xScaleTime = scaleTime()
        .domain(extent(this.dataGroups, d => (d as moment.Moment).valueOf()))
        .range([0, this.width]);
    } else {
      this.xScalePoint = scalePoint()
        .domain(this.dataGroups.map(d => d as string))
        .range([0, this.width]);
    }

    if (!this.xAxisVisible) {
      this.xAxis.selectChildren().remove();
      return;
    }

    const ticks = Math.floor(this.width / 80);
    const axisGenerator = axisBottom(this.xScale).ticks(ticks).tickSize(0).tickPadding(10);

    if (this.dataGroupLookup == DatasetGroupLookup.DateDay) {
      axisGenerator.ticks(timeDay.every(1)).tickFormat(timeFormat('%b %d'));
    } else if (this.dataGroupLookup == DatasetGroupLookup.DateWeek) {
      axisGenerator.ticks(timeWeek.every(1)).tickFormat(timeFormat('%b %d'));
    } else if (this.dataGroupLookup == DatasetGroupLookup.DateMonth) {
      axisGenerator.ticks(timeMonth.every(1)).tickFormat(timeFormat('%B'));
    } else if (this.dataGroupLookup == DatasetGroupLookup.DateQuarter) {
      axisGenerator.ticks(timeMonth.every(3)).tickFormat(timeFormat('Q%q - %Y'));
    } else if (this.dataGroupLookup == DatasetGroupLookup.DateYear) {
      axisGenerator.ticks(timeYear.every(1)).tickFormat(timeFormat('%Y'));
    } else if (this.dataGroupLookup == DatasetGroupLookup.DateHour) {
      axisGenerator.ticks(timeHour.every(1)).tickFormat(timeFormat('%H:%M'));
    } else if (this.dataGroupLookup == DatasetGroupLookup.DateMinute) {
      axisGenerator.ticks(timeMinute.every(15)).tickFormat(timeFormat('%H:%M'));
    } else if (this.dataGroupLookup == DatasetGroupLookup.DateSecond) {
      axisGenerator.ticks(timeSecond.every(15)).tickFormat(timeFormat(':%S'));
    }

    const axis = this.xAxis.attr('transform', `translate(0,${this.height})`).call(axisGenerator);

    fitXAxisLabelWithVisibility(axis, this.el.nativeElement);

    this.setAxisClasses(axis);
  }

  initYAxis() {
    this.yAxis = this.svg.append('g').attr('class', 'chart-axis chart-axis_y');
  }

  renderYAxis() {
    const domain = extent(this.dataTotal, d => d.item.value);
    const domainExpand = 0.05 * Math.abs(domain[1] - domain[0]);

    if (domain[0] !== 0) {
      domain[0] -= domainExpand;
    }

    domain[1] += domainExpand;

    if (isSet(this.min)) {
      domain[0] = this.min;
    }

    if (isSet(this.max)) {
      domain[1] = this.max;
    }

    this.yScale = scaleLinear().domain(domain).range([this.height, 0]);

    if (!this.yAxisVisible) {
      this.yAxis.selectChildren().remove();
      return;
    }

    const ticks = Math.floor(this.height / 50);
    const axisGenerator = axisLeft(this.yScale)
      .ticks(ticks)
      .tickSize(-this.width)
      .tickPadding(10)
      .tickFormat(value => {
        if (!isSet(this.yFormat)) {
          return value;
        }

        return numeral(value).format(this.yFormat);
      });

    const axis = this.yAxis.call(axisGenerator);

    this.setAxisClasses(axis);
  }

  setAxisClasses(axis: Selection<SVGElement, any, any, any>) {
    axis.selectAll('.domain').attr('class', 'chart-axis-domain domain');
    axis.selectAll('.tick').attr('class', 'chart-axis-tick-group tick');
    axis.selectAll('.chart-axis-tick-group line').attr('class', 'chart-axis-tick');
    axis.selectAll('.chart-axis-tick-group text').attr('class', 'chart-axis-label');
  }

  fitYAxis() {
    const width = this.yAxis ? getYAxisWidth(this.yAxis) : 0;

    this.margin.left = width + 10;
    this.width = this.canvasElement.nativeElement.offsetWidth - this.margin.left - this.margin.right;
    this.svg.attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  renderLine() {
    const lineGenerator = line<DataGroup<number, string | moment.Moment>>()
      .curve(this.smooth ? curveMonotoneX : curveLinear)
      .x(d => {
        if (this.xScaleTime) {
          return this.xScaleTime(d.group as moment.Moment);
        } else if (this.xScalePoint) {
          return this.xScalePoint(d.group as string);
        }
      })
      .y(d => this.yScale(d.value));

    this.svg
      .selectAll('.chart-line')
      .data(this.data)
      .join('path')
      .attr('class', (d, i) => `chart-line chart-line_index-${i}`)
      .attr('stroke', (d, i) => {
        const lineGradient = this.getId(`line-gradient-${i}`);
        return `url(#${lineGradient})`;
      })
      .attr('stroke-width', isSet(this.lineWidth) ? this.lineWidth : null)
      .attr('d', d => lineGenerator(d.dataset));

    // const areaGenerator = area<DataGroup<number, string | moment.Moment>>()
    //   .x(d => {
    //     if (this.xScaleTime) {
    //       return this.xScaleTime(d.group as moment.Moment);
    //     } else if (this.xScalePoint) {
    //       return this.xScalePoint(d.group as string);
    //     }
    //   })
    //   .y0(this.height)
    //   .y1(d => this.yScale(d.value));

    this.svg
      .selectAll('.chart-line-area')
      .data(this.data)
      .join('path')
      .attr('class', (d, i) => `chart-line-area chart-line-area_index-${i}`)
      .attr('fill', (d, i) => {
        const fillGradient = this.getId(`fill-gradient-${i}`);
        return this.lineArea ? `url(#${fillGradient})` : 'transparent';
      })
      // .attr('d', d => areaGenerator(d.dataset));
      .attr('d', d => {
        if (d.dataset.length) {
          const lineValues = lineGenerator(d.dataset).slice(1);
          const splitValues = lineValues.split(',');
          return `M0,${this.height},${lineValues},l0,${this.height - parseFloat(splitValues[splitValues.length - 1])}`;
        } else {
          return null;
        }
      });
  }

  renderGradients() {
    this.svg
      .selectAll('.chart-line-gradient')
      .data(this.data.map((item, i) => ({ dataset: item, index: i })))
      .join('linearGradient')
      .attr('id', d => this.getId(`line-gradient-${d.index}`))
      .attr('class', 'chart-line-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', '0%')
      .attr('y1', d => {
        const minValue = min(d.dataset.dataset, item => item.value);
        return this.yScale(minValue);
      })
      .attr('x2', '0%')
      .attr('y2', d => {
        const maxValue = max(d.dataset.dataset, item => item.value);
        return this.yScale(maxValue);
      })
      .selectAll('stop')
      .data(d => {
        const colorHex = getColorHex(this.data[d.index].color);
        const clr = parseColor(colorHex, '#000');
        return [
          { offset: '0%', color: clr.darken(0.2) },
          { offset: '100%', color: clr.lighten(0.2) }
        ];
      })
      .join('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);

    this.svg
      .selectAll('.chart-line-area-gradient')
      .data(this.data.map((item, i) => ({ dataset: item, index: i })))
      .join('linearGradient')
      .attr('id', d => this.getId(`fill-gradient-${d.index}`))
      .attr('class', 'chart-line-area-gradient')
      .attr('gradientUnits', 'objectBoundingBox')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%')
      .selectAll('stop')
      .data(d => {
        const colorHex = getColorHex(this.data[d.index].color);
        const clr = parseColor(colorHex, '#000');
        return [
          { offset: '0%', color: clr.alpha(0) },
          { offset: '100%', color: clr.alpha(0.18) }
        ];
      })
      .join('stop')
      .attr('offset', d => d.offset)
      .attr('stop-color', d => d.color);
  }

  initDatasetHover() {
    combineLatest(this.hoverDatasetIndex$, this.hoverLegendDatasetIndex$)
      .pipe(untilDestroyed(this))
      .subscribe(([hoverDatasetIndex, hoverLegendDatasetIndex]) => {
        const hoverIndex = [hoverDatasetIndex, hoverLegendDatasetIndex].find(item => isSet(item));

        this.data.forEach((item, i) => {
          const lineNodes = this.svg.selectAll<SVGRectElement, any>(`.chart-line_index-${i}`).nodes();
          const lineAreaNodes = this.svg.selectAll<SVGRectElement, any>(`.chart-line-area_index-${i}`).nodes();
          if (!isSet(hoverIndex) || i === hoverIndex) {
            lineNodes.forEach(node => node.classList.remove('chart-line_disabled'));
            lineAreaNodes.forEach(node => node.classList.remove('chart-line-area_disabled'));
          } else {
            lineNodes.forEach(node => node.classList.add('chart-line_disabled'));
            lineAreaNodes.forEach(node => node.classList.add('chart-line-area_disabled'));
          }
        });
      });

    this.hoverDatasetGroup$.pipe(untilDestroyed(this)).subscribe(hoverDatasetGroup => {
      this.updateInteractiveClasses(!!hoverDatasetGroup);
    });
  }

  initInteractive() {
    this.interactiveRect = this.svg.append('rect').attr('class', 'chart-interactive');
  }

  renderInteractive() {
    this.interactiveRect.attr('width', this.width).attr('height', this.height);
  }

  updateInteractiveClasses(hoverGroup: boolean) {
    if (!this.interactiveRect) {
      return;
    }

    const nodes = this.interactiveRect.nodes();
    const activeClass = 'chart-interactive_clickable';

    if (this.dataClickEnabled && hoverGroup) {
      nodes.forEach(node => {
        if (!node.classList.contains(activeClass)) {
          node.classList.add(activeClass);
        }
      });
    } else {
      nodes.forEach(node => {
        if (node.classList.contains(activeClass)) {
          node.classList.remove(activeClass);
        }
      });
    }
  }

  initEvents() {
    this.lineCircle = this.svg
      .insert('circle', () => this.interactiveRect.node())
      .attr('class', 'chart-line-circle')
      .attr('r', 5);

    // combineLatest(
    //   merge(
    //     fromEvent<MouseEvent>(rect.node(), 'mouseenter').pipe(map(e => ({ hover: true, e: e }))),
    //     fromEvent<MouseEvent>(rect.node(), 'mouseleave').pipe(map(() => ({ hover: false, e: undefined })))
    //   )
    //     .pipe(debounce(value => timer(value.hover ? 0 : 10)))
    //     .pipe(startWith({ hover: false, e: undefined })),
    //   merge(
    //     fromEvent<MouseEvent>(this.tooltipContainerElement.nativeElement, 'mouseenter').pipe(
    //       map(e => ({ hover: true, e: e }))
    //     ),
    //     fromEvent<MouseEvent>(this.tooltipContainerElement.nativeElement, 'mouseleave').pipe(
    //       map(() => ({ hover: false, e: undefined }))
    //     )
    //   )
    //     .pipe(debounce(value => timer(value.hover ? 0 : 10)))
    //     .pipe(startWith({ hover: false, e: undefined }))
    // )
    //   .pipe(
    //     distinctUntilChanged((lhs, rhs) => {
    //       const [lhsChart, lhsTooltip] = lhs;
    //       const [rhsChart, rhsTooltip] = rhs;
    //       return (lhsChart.hover || lhsTooltip.hover) === (rhsChart.hover || rhsTooltip.hover);
    //     }),
    //     untilDestroyed(this)
    //   )
    //   .subscribe(([chart, tooltip]) => {
    //     if (chart.hover || tooltip.hover) {
    //       this.mouseover(chart.e);
    //     } else {
    //       this.mouseout();
    //     }
    //   });

    merge(
      fromEvent<MouseEvent>(this.interactiveRect.node(), 'mouseenter').pipe(map(e => ({ hover: true, e: e }))),
      fromEvent<MouseEvent>(this.interactiveRect.node(), 'mouseleave').pipe(map(() => ({ hover: false, e: undefined })))
    )
      .pipe(untilDestroyed(this))
      .subscribe(chart => {
        if (chart.hover) {
          this.onMouseEnter(chart.e);
        } else {
          this.onMouseLeave();
        }
      });

    fromEvent(this.interactiveRect.node(), 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(e => this.onMouseMove(e));

    fromEvent<PointerEvent>(this.interactiveRect.node(), 'click')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        const hoverDatasetGroup = this.hoverDatasetGroup$.value;
        if (hoverDatasetGroup) {
          const group = this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group;
          const group2 = this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group2;
          const group3 = this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].group3;
          const value = this.data[hoverDatasetGroup.datasetIndex].dataset[hoverDatasetGroup.groupIndex].value;

          this.onClick({
            datasetIndex: hoverDatasetGroup.datasetIndex,
            groupIndex: hoverDatasetGroup.groupIndex,
            group: group,
            group2: group2,
            group3: group3,
            value: value,
            position: {
              x: e.clientX,
              y: e.clientY
            }
          });
        }
      });
  }

  showDatasetTooltip(closestDataset: DataTotalItem & { x: number; y: number }, reuse = false) {
    let group: string;

    if (closestDataset.item.group instanceof moment) {
      const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
      group = (closestDataset.item.group as moment.Moment).format(format);
    } else {
      group = closestDataset.item.group as string;
    }

    this.dataTooltip.show({
      group: group,
      datasets: this.data
        .filter(dataset => dataset.dataset[closestDataset.itemIndex])
        .map((dataset, i) => {
          const defaultLabel = this.data.length > 1 ? `Dataset ${i + 1}` : undefined;
          return {
            value: dataset.dataset[closestDataset.itemIndex].value,
            valueFormat: dataset.format,
            label: isSet(dataset.name) ? dataset.name : defaultLabel,
            color: dataset.color
          };
        }),
      datasetActiveIndex: closestDataset ? closestDataset.datasetIndex : undefined,
      valueFormat: this.yFormat,
      colorCircle: true,
      x: this.margin.left + closestDataset.x,
      y: this.margin.top + closestDataset.y,
      portalOutlet: this.portalOutlet,
      reuse: reuse
    });
  }

  onMouseEnter(e) {
    if (!this.interactive) {
      return;
    }

    this.lineCircle.node().classList.add('chart-line-circle_active');

    this.dataTooltip.close();

    const closestDataset = this.getClosestDataset(e);

    if (closestDataset) {
      const lineGradient = this.getId(`line-gradient-${closestDataset.datasetIndex}`);

      this.lineCircle.attr('cx', closestDataset.x).attr('cy', closestDataset.y).attr('stroke', `url(#${lineGradient})`);

      this.hoverDatasetIndex$.next(closestDataset.datasetIndex);
      this.hoverDatasetGroup$.next({ datasetIndex: closestDataset.datasetIndex, groupIndex: closestDataset.itemIndex });

      let group: string;
      const value = closestDataset.item.value;

      if (closestDataset.item.group instanceof moment) {
        const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
        group = (closestDataset.item.group as moment.Moment).format(format);
      } else {
        group = closestDataset.item.group as string;
      }

      this.groupHover.next({ group: group, value: value });

      if (this.tooltips) {
        this.showDatasetTooltip(closestDataset);
      }
    }
  }

  getClosestGroup(e: MouseEvent): number {
    const [pointerX, pointerY] = pointer(e);

    if (this.xScaleTime) {
      const x0 = this.xScaleTime.invert(pointerX);
      return bisectCenter(
        this.dataGroups.map(item => (item as moment.Moment).toDate()),
        x0
      );
    } else if (this.xScalePoint) {
      const x0 = this.xScalePoint(pointerX);
      return bisectCenter(range(this.width), x0);
    }
  }

  getClosestDataset(e: MouseEvent): DataTotalItem & { x: number; y: number } {
    const [pointerX, pointerY] = pointer(e);
    let dataTotal = this.dataTotal;

    if (this.selectedDatasetIndexes) {
      dataTotal = dataTotal.filter(item => this.selectedDatasetIndexes[item.datasetIndex]);
    }

    const dataClosest = least(dataTotal, item => {
      const itemY = this.yScale(item.item.value);

      if (this.xScaleTime) {
        const itemX = this.xScaleTime(item.item.group as moment.Moment);
        return Math.hypot(itemX - pointerX, itemY - pointerY);
      } else if (this.xScalePoint) {
        const itemX = this.xScalePoint(item.item.group as string);
        return Math.hypot(itemX - pointerX, itemY - pointerY);
      }
    });

    if (!dataClosest) {
      return;
    }

    let x: number;
    const y = this.yScale(dataClosest.item.value);

    if (this.xScaleTime) {
      x = this.xScaleTime(dataClosest.item.group as moment.Moment);
    } else if (this.xScalePoint) {
      x = this.xScalePoint(dataClosest.item.group as string);
    }

    return {
      ...dataClosest,
      x: x,
      y: y
    };
  }

  onMouseMove(e) {
    if (!this.interactive) {
      return;
    }

    const closestDataset = this.getClosestDataset(e);

    if (closestDataset) {
      const lineGradient = this.getId(`line-gradient-${closestDataset.datasetIndex}`);

      this.lineCircle.attr('cx', closestDataset.x).attr('cy', closestDataset.y).attr('stroke', `url(#${lineGradient})`);

      this.hoverDatasetIndex$.next(closestDataset.datasetIndex);
      this.hoverDatasetGroup$.next({ datasetIndex: closestDataset.datasetIndex, groupIndex: closestDataset.itemIndex });

      let group: string;
      const value = closestDataset.item.value;

      if (closestDataset.item.group instanceof moment) {
        const format = getDateFormatByLookup(this.dataGroupLookup) || 'lll';
        group = (closestDataset.item.group as moment.Moment).format(format);
      } else {
        group = closestDataset.item.group as string;
      }

      this.groupHover.next({ group: group, value: value });

      if (this.tooltips) {
        this.showDatasetTooltip(closestDataset, true);
      }
    } else {
      this.hoverDatasetGroup$.next(undefined);
    }
  }

  onMouseLeave() {
    if (!this.interactive) {
      return;
    }

    this.lineCircle.node().classList.remove('chart-line-circle_active');

    this.hoverDatasetIndex$.next(undefined);
    this.hoverDatasetGroup$.next(undefined);
    this.groupHover.next(undefined);

    this.dataTooltip.close();
  }

  onClick(options: DataClickEvent) {
    if (!this.dataClickEnabled) {
      return;
    }

    this.dataClick.emit(options);
  }

  get selectedDatasetCount(): number {
    return this.selectedDatasetIndexes ? keys(this.selectedDatasetIndexes).length : 0;
  }

  toggleSelectedDatasetIndex(index: number) {
    if (!this.interactive) {
      return;
    }

    if (!this.selectedDatasetIndexes) {
      this.selectedDatasetIndexes = fromPairs(this.data.map((item, i) => [i, true]).filter(([i]) => i !== index));
    } else if (this.selectedDatasetIndexes[index]) {
      delete this.selectedDatasetIndexes[index];
    } else {
      this.selectedDatasetIndexes[index] = true;

      if (this.selectedDatasetCount === this.data.length) {
        this.selectedDatasetIndexes = undefined;
      }
    }

    this.cd.markForCheck();

    this.data.forEach((item, i) => {
      const lineNodes = this.svg.selectAll<SVGRectElement, any>(`.chart-line_index-${i}`).nodes();
      const lineAreaNodes = this.svg.selectAll<SVGRectElement, any>(`.chart-line-area_index-${i}`).nodes();

      if (!this.selectedDatasetIndexes || this.selectedDatasetIndexes[i]) {
        lineNodes.forEach(node => node.classList.remove('chart-line_hidden'));
        lineAreaNodes.forEach(node => node.classList.remove('chart-line-area_hidden'));
      } else {
        lineNodes.forEach(node => node.classList.add('chart-line_hidden'));
        lineAreaNodes.forEach(node => node.classList.add('chart-line-area_hidden'));
      }
    });
  }

  onLegendDatasetMouseEnter(index: number) {
    if (!this.interactive) {
      return;
    }

    this.hoverLegendDatasetIndex$.next(index);
  }

  onLegendDatasetMouseLeave() {
    if (!this.interactive) {
      return;
    }

    this.hoverLegendDatasetIndex$.next(undefined);
  }

  rerender() {
    this.initBounds();
    this.renderYAxis();
    this.fitYAxis();
    this.renderXAxis();
    this.renderLine();
    this.renderGradients();
    this.renderInteractive();
  }

  onResize() {
    this.rerender();
  }

  colorDisplay(value: string): string {
    return getColorHexStr(value);
  }
}
