import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AppDragDrop, DragAxis } from '@common/drag-drop2';
import { CustomizeBarContext } from '@modules/customize-bar';
import { createFormFieldFactory } from '@modules/fields';
import { MenuBlock, MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { capitalize, controlValue, getNumberOrdinal, isSet } from '@shared';

import { CustomizeBarPagesEditComponent } from '../customize-bar-pages-edit/customize-bar-pages-edit.component';
import { LayoutGroup, LayoutOption } from '../menu-block-layout-overlay/menu-block-layout-overlay.component';
import { MenuBlockControl } from '../project-settings/menu-block.control';
import { MenuUpdateForm } from '../project-settings/menu-update.form';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';

interface MenuBlockItem {
  uid: string;
  title: string;
  control: MenuBlockControl;
}

@Component({
  selector: 'app-customize-bar-menu-edit',
  templateUrl: './customize-bar-menu-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarMenuEditComponent implements OnInit, OnDestroy {
  @Input() menuForm: MenuUpdateForm;
  @Input() settingsForm: ProjectSettingsUpdateForm;
  @Input() blockControlPreviewHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  @Output() blockControlHover = new EventEmitter<MenuBlockControl>();

  createField = createFormFieldFactory();
  fromAppSettings = false;
  layoutGroups: LayoutGroup[] = [
    {
      label: 'Vertical',
      options: [
        {
          layout: MenuBlockLayout.LeftThin,
          image: 'layout-primary-menu-left',
          title: 'Thin width',
          subtitle: 'Left position',
          color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftThin)
        },
        {
          layout: MenuBlockLayout.LeftWide,
          image: 'layout-menu-left',
          title: 'Wide width',
          subtitle: 'Left position'
        }
      ]
    },
    {
      label: 'Horizontal',
      options: [
        {
          layout: MenuBlockLayout.TopThin,
          image: 'layout-primary-menu-top',
          title: 'Primary menu',
          subtitle: 'Top position',
          color: MenuBlockLayouts.getDefaultColor(MenuBlockLayout.LeftThin)
        },
        {
          layout: MenuBlockLayout.TopThin,
          image: 'layout-menu-top',
          title: 'Secondary menu',
          subtitle: 'Top position',
          color: ''
        },
        {
          layout: MenuBlockLayout.TopContentThin,
          image: 'layout-menu-top-content',
          title: 'Inner menu',
          subtitle: 'Content top position'
        }
      ]
    }
  ];
  blockItems: MenuBlockItem[] = [];
  blockItemParamSubscription: Subscription;
  blockControlHover$ = new BehaviorSubject<MenuBlockControl>(undefined);
  editingBlockItem: MenuBlockItem;
  dragAxis = DragAxis;

  trackMenuBlockItemFn(i, item: MenuBlockItem) {
    return item.control.instance.uid;
  }

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private activatedRoute: ActivatedRoute,
    private customizeBarContext: CustomizeBarContext,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.fromAppSettings = !!this.activatedRoute.snapshot.queryParams['app_settings'];

    controlValue(this.menuForm.controls.blocks)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateBlockItems());

    this.blockControlHover$.pipe(untilDestroyed(this)).subscribe(value => this.blockControlHover.emit(value));
  }

  ngOnDestroy(): void {}

  updateBlockItems() {
    if (this.blockItemParamSubscription) {
      this.blockItemParamSubscription.unsubscribe();
      this.blockItemParamSubscription = undefined;
    }

    let leftI = 0;
    let topI = 0;

    this.blockItems = this.menuForm.controls.blocks.controls.map(control => {
      let title: string;

      if (!control.controls.layout.value) {
        title = 'New menu';
      } else if (MenuBlockLayouts.isLeft(control.controls.layout.value)) {
        title = `Left ${capitalize(getNumberOrdinal(leftI + 1))} menu`;
        leftI += 1;
      } else if (MenuBlockLayouts.isTop(control.controls.layout.value)) {
        title = `Top ${capitalize(getNumberOrdinal(topI + 1))} menu`;
        topI += 1;
      }

      return {
        uid: control.instance ? control.instance.uid : undefined,
        title: title,
        control: control
      };
    });
    this.cd.markForCheck();

    this.blockItemParamSubscription = this.activatedRoute.params
      .pipe(
        map(params => params['uid'] as string),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(uid => {
        if (isSet(uid)) {
          const blockItem = this.blockItems.find(item => item.uid == uid);
          if (blockItem) {
            this.editMenuBlockItem(blockItem);
          } else {
            this.navigateDefault();
          }
        } else {
          this.resetEditMenuBlockItem();
        }
      });
  }

  addMenuBlock(option: LayoutOption) {
    const block = new MenuBlock();

    block.generateUid();
    block.layout = option.layout;
    block.accentColor = option.color;

    const control = this.menuForm.controls.blocks.appendControl(block);

    control.applyDefaultState();
  }

  navigateMenuBlockItem(item: MenuBlockItem) {
    const link = this.currentProjectStore.instance.settingsMenuLink(item.uid);
    this.routing.navigateApp(link, { queryParams: { ...(this.fromAppSettings && { app_settings: '1' }) } });
  }

  navigateDefault() {
    const link = this.currentProjectStore.instance.settingsMenuLink();
    this.routing.navigateApp(link);
  }

  editMenuBlockItem(item: MenuBlockItem) {
    const prevItemUid = this.editingBlockItem ? this.editingBlockItem.uid : undefined;
    const itemUid = item.uid;

    if (prevItemUid === itemUid) {
      return;
    }

    this.resetEditMenuBlockItem();

    this.editingBlockItem = item;

    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarPagesEditComponent,
      inputs: {
        blockControl: item.control,
        startItemsControl: item.control.controls.start_items,
        centerItemsControl: item.control.controls.center_items,
        endItemsControl: item.control.controls.end_items,
        title: item.title,
        layout: item.control.controls.layout.value
      }
    });
  }

  resetEditMenuBlockItem() {
    const currentComponentsCount = this.customizeBarContext.settingsComponents.length;
    if (currentComponentsCount <= 1) {
      return;
    }

    this.editingBlockItem = undefined;

    const popComponents = currentComponentsCount - 1;
    range(popComponents).forEach(() => this.customizeBarContext.popSettingsComponent());
  }

  dragDrop(event: AppDragDrop<MenuBlockControl[]>) {
    moveItemInArray(this.menuForm.controls.blocks.controls, event.previousIndex, event.currentIndex);
    this.menuForm.controls.blocks.updateValueAndValidity();
  }

  backToAppSettings() {
    this.routing.navigateApp(this.currentProjectStore.instance.settingsLayoutLink('appearance'));
  }
}
