import pickBy from 'lodash/pickBy';

import { ActionItem, ViewSettingsAction } from '@modules/actions';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input, ParameterField } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { ListModelDescriptionQuery } from '@modules/queries';
import { ViewMapping } from '@modules/views';
import { isSet } from '@shared';

import { migrateModelDescriptionDataSource } from '../utils/migration';
import { FieldActions } from './field-actions';
import { FieldName } from './field-name';
import { ListLayoutSettings } from './list-layout-settings';
import { MapLocationStorage } from './map-location-storage';

export class MapSettings implements ListLayoutSettings {
  type = ListLayoutType.Map;
  header = true;
  titleInput: Input;
  dataSource: ListModelDescriptionDataSource;
  cardCustomView: string;
  cardCustomViewMappings: ViewMapping[] = [];
  actions: ViewSettingsAction[] = [];
  cardClickAction: ActionItem;
  modelActions: ViewSettingsAction[] = [];
  columnActions: FieldActions[] = [];
  customActions: { name: string; action: ActionItem }[] = [];
  searchEnabled = true;
  searchResource: string;
  searchQuery: ListModelDescriptionQuery;
  searchLive = true;
  searchParameters: ParameterField[] = [];
  searchInputs: Input[] = [];
  sortingField: string;
  sortingAsc = true;
  displayFilters = true;
  filterFields: FieldName[] = [];
  locationStorage: MapLocationStorage;
  locationField: string;
  locationLatitudeField: string;
  locationLongitudeField: string;
  locationInput: Input;
  zoomInput: Input;
  markerColor: string;
  markerColorInput: Input;
  markerSize: number;
  markerSizeInput: Input;
  markersFitOnChange = false;
  tooltip: string;

  deserialize(data: Object): MapSettings {
    this.cardCustomView = data['card_custom_view'];
    this.searchEnabled = data['search_enabled'];
    this.searchResource = data['search_resource'];
    this.sortingField = data['sorting_field'];
    this.tooltip = data['tooltip'];

    if (data['title_input']) {
      this.titleInput = new Input().deserialize(data['title_input']);
    } else if (data['title']) {
      // Backward compatibility
      this.titleInput = new Input().deserializeFromStatic('value', data['title']);
    }

    if (data['data_source']) {
      this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
    } else if (data['resource']) {
      // Backward compatibility
      this.dataSource = migrateModelDescriptionDataSource(ListModelDescriptionDataSource, ListModelDescriptionQuery, {
        resource: data['resource'],
        query: data['get_query'],
        parameters: data['parameters'],
        inputs: data['inputs'],
        columns: data['columns']
      });
    }

    if (data['card_custom_view_mappings']) {
      this.cardCustomViewMappings = data['card_custom_view_mappings'].map(item => new ViewMapping().deserialize(item));
    }

    if (data['display_filters'] !== undefined) {
      this.displayFilters = data['display_filters'];
    }

    if (data['filter_fields']) {
      this.filterFields = data['filter_fields'].map(item => {
        return {
          name: item['name']
        };
      });
    }

    if (data['header'] != undefined) {
      this.header = data['header'];
    }

    if (data['search_query']) {
      this.searchQuery = new ListModelDescriptionQuery().deserialize(data['search_query']);
    }

    if (data['search_live'] !== undefined) {
      this.searchLive = data['search_live'];
    }

    if (data['search_parameters']) {
      this.searchParameters = data['search_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['search_inputs']) {
      this.searchInputs = data['search_inputs'].map(item => new Input().deserialize(item));
    }

    if (data['card_click_action']) {
      this.cardClickAction = new ActionItem().deserialize(data['card_click_action']);
    }

    if (data['location_storage']) {
      this.locationStorage = data['location_storage'];
    }

    this.locationField = data['location_field'];
    this.locationLatitudeField = data['location_latitude_field'];
    this.locationLongitudeField = data['location_longitude_field'];

    if (data['location_input']) {
      this.locationInput = new Input().deserialize(data['location_input']);
    }

    if (data['zoom_input']) {
      this.zoomInput = new Input().deserialize(data['zoom_input']);
    }

    this.markerColor = data['marker_color'];
    this.markerSize = data['marker_size'];
    this.markersFitOnChange = data['markers_fit_on_change'];

    if (data['marker_color_input']) {
      this.markerColorInput = new Input().deserialize(data['marker_color_input']);
    }

    if (data['marker_size_input']) {
      this.markerSizeInput = new Input().deserialize(data['marker_size_input']);
    }

    if (data['actions']) {
      this.actions = data['actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (data['model_actions']) {
      this.modelActions = data['model_actions'].map(item => new ViewSettingsAction().deserialize(item));
    }

    if (data['column_actions']) {
      this.columnActions = data['column_actions']
        .filter(item => item['actions'])
        .map(item => {
          return {
            name: item['name'],
            actions: item['actions'].map(subItem => new ViewSettingsAction().deserialize(subItem))
          };
        });
    } else if (data['data_source'] && data['data_source']['columns']) {
      // Backward compatibility
      this.columnActions = data['data_source']['columns']
        .filter(item => item['action'])
        .map(item => {
          return {
            name: item['name'],
            actions: [new ViewSettingsAction().deserialize(item['action'])]
          };
        });
    }

    if (data['custom_actions']) {
      this.customActions = data['custom_actions'].map(item => ({
        name: item['name'],
        action: new ActionItem().deserialize(item['action'])
      }));
    }

    if (data['sorting_asc'] != undefined) {
      this.sortingAsc = data['sorting_asc'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      type: this.type,
      header: this.header,
      title_input: this.titleInput ? this.titleInput.serialize() : null,
      data_source: this.dataSource ? this.dataSource.serialize() : undefined,
      card_custom_view: this.cardCustomView,
      card_custom_view_mappings: this.cardCustomViewMappings.map(item => item.serialize()),
      search_enabled: this.searchEnabled,
      search_resource: this.searchResource,
      search_query: this.searchQuery ? this.searchQuery.serialize() : undefined,
      search_live: this.searchLive,
      search_parameters: this.searchParameters.map(item => item.serialize()),
      search_inputs: this.searchInputs.map(item => item.serialize()),
      card_click_action: this.cardClickAction ? this.cardClickAction.serialize() : undefined,
      location_storage: this.locationStorage,
      location_field: this.locationField,
      location_latitude_field: this.locationLatitudeField,
      location_longitude_field: this.locationLongitudeField,
      location_input: this.locationInput ? this.locationInput.serialize() : undefined,
      zoom_input: this.zoomInput ? this.zoomInput.serialize() : undefined,
      marker_color: this.markerColor,
      marker_color_input: this.markerColorInput ? this.markerColorInput.serialize() : undefined,
      marker_size: this.markerSize,
      marker_size_input: this.markerSizeInput ? this.markerSizeInput.serialize() : undefined,
      markers_fit_on_change: this.markersFitOnChange,
      actions: this.actions.map(item => item.serialize()),
      model_actions: this.modelActions.map(item => item.serialize()),
      column_actions: this.columnActions.map(item => {
        return {
          name: item.name,
          actions: item.actions.map(action => action.serialize())
        };
      }),
      custom_actions: this.customActions.map(item => ({ name: item.name, action: item.action.serialize() })),
      sorting_field: this.sortingField,
      sorting_asc: this.sortingAsc,
      display_filters: this.displayFilters,
      filter_fields: this.filterFields.map(item => {
        return {
          name: item.name
        };
      }),
      tooltip: this.tooltip
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isConfigured() {
    if (this.locationStorage == MapLocationStorage.PostgreSQL) {
      return isSet(this.locationField);
    } else if (this.locationStorage == MapLocationStorage.TwoFields) {
      return isSet(this.locationLatitudeField) && isSet(this.locationLongitudeField);
    } else if (this.locationStorage == MapLocationStorage.Object) {
      return isSet(this.locationField);
    } else {
      return false;
    }
  }

  get analyticsName(): string {
    return 'map';
  }

  get analyticsGenericName(): string {
    if (!this.analyticsName) {
      return;
    }
    return ['component', this.analyticsName].join('_');
  }
}
