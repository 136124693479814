import { ProjectSettings, ProjectSettingsName } from '@modules/projects';
import { Workflow } from '@modules/workflow';
import { isSet } from '@shared';

import { HomeType } from './home-type';
import { SignUpField } from './sign-up-field';

export interface CrispIntegration {
  id?: string;
}

export interface DriftIntegration {
  id?: string;
}

export interface FacebookPixelIntegration {
  id?: string;
}

export interface GoogleAnalyticsIntegration {
  id?: string;
}

export interface GoogleTagManagerIntegration {
  id?: string;
}

export interface HotjarIntegration {
  site_id?: string;
}

export interface HubspotIntegration {
  id?: string;
}

export interface IntercomIntegration {
  workspace_id?: string;
}

export class AllProjectSettings {
  public defaultTheme: string;
  public accentColor: string;
  public fontRegular: string;
  public fontHeading: string;
  public appendScripts: string;
  public appendStyles: string;
  public collaborationEnabled = true; // TODO: Remove unused
  public activityLogEnabled = true; // TODO: Remove unused
  public signUpFields: SignUpField[] = [];
  public homeType: HomeType = HomeType.FirstMenuItem;
  public homePageUid: string;
  public homeWorkflow: Workflow;
  public crisp: CrispIntegration;
  public facebookPixel: FacebookPixelIntegration;
  public drift: DriftIntegration;
  public googleAnalytics: GoogleAnalyticsIntegration;
  public googleTagManager: GoogleTagManagerIntegration;
  public hubspot: HubspotIntegration;
  public intercom: IntercomIntegration;
  public hotjar: HotjarIntegration;

  settingsTypes: {
    name: ProjectSettingsName;
    deserializeValue: (value: Object) => void;
    serializeValue: () => Object;
  }[] = [
    {
      name: ProjectSettingsName.DefaultTheme,
      deserializeValue: value => {
        if (value) {
          this.defaultTheme = value['value'];
        }
      },
      serializeValue: () => (isSet(this.defaultTheme) ? { value: this.defaultTheme } : undefined)
    },
    {
      name: ProjectSettingsName.AccentColor,
      deserializeValue: value => {
        if (value) {
          this.accentColor = value['value'];
        }
      },
      serializeValue: () => (isSet(this.accentColor) ? { value: this.accentColor } : undefined)
    },
    {
      name: ProjectSettingsName.FontRegular,
      deserializeValue: value => {
        if (value) {
          this.fontRegular = value['value'];
        }
      },
      serializeValue: () => (isSet(this.fontRegular) ? { value: this.fontRegular } : undefined)
    },
    {
      name: ProjectSettingsName.FontHeading,
      deserializeValue: value => {
        if (value) {
          this.fontHeading = value['value'];
        }
      },
      serializeValue: () => (isSet(this.fontHeading) ? { value: this.fontHeading } : undefined)
    },
    {
      name: ProjectSettingsName.AppendScripts,
      deserializeValue: value => {
        if (value) {
          this.appendScripts = value['value'];
        }
      },
      serializeValue: () => (isSet(this.appendScripts) ? { value: this.appendScripts } : undefined)
    },
    {
      name: ProjectSettingsName.AppendStyles,
      deserializeValue: value => {
        if (value) {
          this.appendStyles = value['value'];
        }
      },
      serializeValue: () => (isSet(this.appendStyles) ? { value: this.appendStyles } : undefined)
    },
    {
      name: ProjectSettingsName.CollaborationEnabled,
      deserializeValue: value => {
        if (value) {
          this.collaborationEnabled = value['value'];
        }
      },
      serializeValue: () => (isSet(this.collaborationEnabled) ? { value: this.collaborationEnabled } : undefined)
    },
    {
      name: ProjectSettingsName.ActivityLogEnabled,
      deserializeValue: value => {
        if (value) {
          this.activityLogEnabled = value['value'];
        }
      },
      serializeValue: () => (isSet(this.activityLogEnabled) ? { value: this.activityLogEnabled } : undefined)
    },
    {
      name: ProjectSettingsName.SignUpFields,
      deserializeValue: value => {
        if (value) {
          this.signUpFields = value['fields'] ? value['fields'].map(item => new SignUpField().deserialize(item)) : [];
        }
      },
      serializeValue: () => ({ fields: this.signUpFields.map(item => item.serialize()) })
    },
    {
      name: ProjectSettingsName.Home,
      deserializeValue: value => {
        if (value) {
          this.homeType = value['type'] ? value['type'] : HomeType.FirstMenuItem;
          this.homePageUid = value['page'];
          this.homeWorkflow = value['workflow'] ? new Workflow().deserialize(value['workflow']) : undefined;
        }
      },
      serializeValue: () => ({
        type: this.homeType,
        page: this.homePageUid,
        workflow: this.homeWorkflow ? this.homeWorkflow.serialize() : undefined
      })
    },
    {
      name: ProjectSettingsName.Crisp,
      deserializeValue: value => {
        if (value) {
          this.crisp = value as CrispIntegration;
        }
      },
      serializeValue: () => this.crisp
    },
    {
      name: ProjectSettingsName.Drift,
      deserializeValue: value => {
        if (value) {
          this.drift = value as DriftIntegration;
        }
      },
      serializeValue: () => this.drift
    },
    {
      name: ProjectSettingsName.FacebookPixel,
      deserializeValue: value => {
        if (value) {
          this.facebookPixel = value as FacebookPixelIntegration;
        }
      },
      serializeValue: () => this.facebookPixel
    },
    {
      name: ProjectSettingsName.GoogleAnalytics,
      deserializeValue: value => {
        if (value) {
          this.googleAnalytics = value as GoogleAnalyticsIntegration;
        }
      },
      serializeValue: () => this.googleAnalytics
    },
    {
      name: ProjectSettingsName.GoogleTagManager,
      deserializeValue: value => {
        if (value) {
          this.googleTagManager = value as GoogleTagManagerIntegration;
        }
      },
      serializeValue: () => this.googleTagManager
    },
    {
      name: ProjectSettingsName.Hotjar,
      deserializeValue: value => {
        if (value) {
          this.hotjar = value as HotjarIntegration;
        }
      },
      serializeValue: () => this.hotjar
    },
    {
      name: ProjectSettingsName.Hubspot,
      deserializeValue: value => {
        if (value) {
          this.hubspot = value as HubspotIntegration;
        }
      },
      serializeValue: () => this.hubspot
    },
    {
      name: ProjectSettingsName.Intercom,
      deserializeValue: value => {
        if (value) {
          this.intercom = value as IntercomIntegration;
        }
      },
      serializeValue: () => this.intercom
    }
  ];

  constructor(options: Partial<AllProjectSettings> = {}) {
    Object.assign(this, options);
  }

  getProjectSettingsValue<T = { value: any }>(settings: ProjectSettings[], name: ProjectSettingsName): T {
    const setting = settings.find(item => item.name == name);
    return setting ? (setting.value as T) : undefined;
  }

  deserialize(settings: ProjectSettings[]): AllProjectSettings {
    this.settingsTypes.forEach(item => {
      const value = this.getProjectSettingsValue(settings, item.name);
      item.deserializeValue(value);
    });

    return this;
  }

  serialize(names?: ProjectSettingsName[]): ProjectSettings[] {
    return this.settingsTypes
      .filter(item => !names || names.includes(item.name))
      .map(item => {
        const value = item.serializeValue();

        return new ProjectSettings({
          name: item.name,
          value: value
        });
      });
  }
}
