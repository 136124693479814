import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';

import { capitalize, isSet, UnexpectedError } from '@shared';

import { Locale, LocaleItem } from './locale';
import { locale as daLocale } from './locale.da';
import { locale as esLocale } from './locale.es';
import { locale as frLocale } from './locale.fr';
import { locale as huLocale } from './locale.hu';
import { locale as itLocale } from './locale.it';
import { locale as jaLocale } from './locale.ja';
import { locale as koLocale } from './locale.ko';
import { locale as plLocale } from './locale.pl';
import { locale as ptLocale } from './locale.pt';
import { locale as ptBrLocale } from './locale.pt-br';
import { locale as ruLocale } from './locale.ru';

export function localeReplaceKey(label: string, options: { context?: string } = {}): string {
  return [label, isSet(options.context) ? [options.context] : []].join('|');
}

export function localeItemKey(item: LocaleItem): string {
  if (typeof item.source == 'object') {
    return localeReplaceKey(item.source.label, { context: item.source.context });
  } else if (typeof item.source == 'string') {
    return localeReplaceKey(item.source);
  }
}

function mapLocale(locale: Locale, checkDuplicateKeys = false): { [label: string]: any } {
  const duplicates: string[] = [];
  const result = locale.items.reduce((acc, item) => {
    const key = localeItemKey(item);

    if (checkDuplicateKeys && acc.hasOwnProperty(key) && !duplicates.includes(key)) {
      duplicates.push(key);
    }

    acc[key] = item.target;
    return acc;
  }, {});

  if (checkDuplicateKeys && duplicates.length) {
    const items = duplicates.map(item => `"${item}"`).join(', ');
    throw new UnexpectedError(`Locale "${capitalize(locale.name)}" has duplicated items: ${items}`);
  }

  return result;
}

function checkLocaleMissingKeys() {
  const allKeys = keys(
    locales.reduce((acc, locale) => {
      const localeReplaces = localesReplaces[locale.language];
      if (localeReplaces) {
        keys(localeReplaces).forEach(key => (acc[key] = true));
      }
      return acc;
    }, {})
  );

  locales.forEach(locale => {
    const missing = allKeys.filter(key => {
      const localeReplaces = localesReplaces[locale.language];
      return localeReplaces && !isSet(localeReplaces[key]);
    });

    if (missing.length) {
      const items = missing.map(item => `"${item}"`).join(', ');
      throw new UnexpectedError(`Locale "${capitalize(locale.name)}" has missing items: ${items}`);
    }
  });
}

export const locales = [
  daLocale,
  frLocale,
  huLocale,
  itLocale,
  jaLocale,
  koLocale,
  plLocale,
  ptBrLocale,
  ptLocale,
  ruLocale,
  esLocale
];

export const localesReplaces: {
  [language: string]: { [label: string]: any };
} = fromPairs(locales.map(item => [item.language, mapLocale(item, true)]));

export const DEFAULT_LANGUAGE = 'en';

checkLocaleMissingKeys();
