import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppDragDrop, DragAxis, moveItemInFormArray, transferFormArrayItem } from '@common/drag-drop2';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { MenuBlockLayout, MenuBlockLayouts } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { BODY_CONTENTFUL_RIGHT_BAR_CLASS } from '@modules/sidebar';
import { addClass, removeClass, TypedChanges } from '@shared';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import { MenuBlockControl } from '../project-settings/menu-block.control';
import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl
} from './customize-bar-pages-edit.form';

@Component({
  selector: 'app-customize-bar-pages-edit',
  templateUrl: './customize-bar-pages-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarPagesEditComponent implements OnInit, OnDestroy, OnChanges {
  @Input() blockControl: MenuBlockControl;
  @Input() startItemsControl: CustomizeBarPagesEditMenuItemArray;
  @Input() centerItemsControl: CustomizeBarPagesEditMenuItemArray;
  @Input() endItemsControl: CustomizeBarPagesEditMenuItemArray;
  @Input() title = 'Menu editing';
  @Input() layout: MenuBlockLayout;

  horizontal = false;
  createdMenuItemControl: CustomizeBarPagesEditMenuItemControl;
  createdMenuItemControlOptions: AddMenuItemOptions;
  dragAxis = DragAxis;

  trackMenuItemFn(i, item: CustomizeBarPagesEditMenuItemControl) {
    return item.menuItem.id;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private currentProjectStore: CurrentProjectStore,
    private customizeService: CustomizeService,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.updateHorizontal();

    addClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS);
  }

  ngOnDestroy(): void {
    removeClass(document.body, BODY_CONTENTFUL_RIGHT_BAR_CLASS);
  }

  ngOnChanges(changes: TypedChanges<CustomizeBarPagesEditComponent>): void {
    if (changes.layout) {
      this.updateHorizontal();
    }
  }

  updateHorizontal() {
    this.horizontal = MenuBlockLayouts.isHorizontal(this.layout);
  }

  addMenuItem(container: CustomizeBarPagesEditMenuItemArray, options: AddMenuItemOptions) {
    this.createdMenuItemControl = container.addControl(options.item, options.prepend);
    this.createdMenuItemControlOptions = options;
    this.cd.markForCheck();

    this.analyticsService.sendEvent(AnalyticsEvent.GA.MenuItem, AnalyticsEventAction.Created);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Menu.MenuItemAdded);
  }

  dragDrop(event: AppDragDrop<CustomizeBarPagesEditMenuItemArray>) {
    if (event.previousContainer === event.container) {
      moveItemInFormArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferFormArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  back() {
    const link = this.currentProjectStore.instance.settingsMenuLink();
    const fromAppSettings = this.activatedRoute.snapshot.queryParams['app_settings'];
    this.routing.navigateApp(link, { queryParams: { ...(fromAppSettings && { app_settings: '1' }) } });
  }
}
